import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import "./profileCover.css";
import firebase from "firebase";
import { firestore } from "../../config/db";
import Spinner from "../../components/spinner/Spinner";
import UpdateFrom from "./UpdateFrom";
import Axios from "axios";
import PageLoader from "../../components/pageLoader/loader";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
export default function ProfileCover() {
  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials;
  const [authExpire, setauthExpire] = useState(false);
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  if (userData === null || tokens.initState) {
    window.location.href = "/signin";
  }
  const actualToken = tokens.credentials.token;
  const userId = tokens.credentials.message._id;

  const [uploadImage, setuploadImage] = useState({
    published_image: "",
    loader: false,
  });
  const handleUploadImage = (value, attr) => {
    setuploadImage({
      ...uploadImage,
      loader: true,
    });

    if (value === "") {
      return setuploadImage({
        published_image: "",
        loader: false,
      });
    } else {
      console.log(value);

      const formData = new FormData();

      formData.append("file", value);
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };

      Axios.put(
        `${process.env.REACT_APP_URL}/users/publisher/upload/avater/${userId}`,
        formData,
        requestOptions
      ).then((data) => {
        console.log(data);
        if (data.data.success) {
          setuploadImage({
            loader: false,
            published_image: data.data.message.avaterUrl,
          });
        } else {
          if (data.message === "jwt expired") {
            setauthExpire(true);
            sessionStorage.setItem(false);
          }
        }
      });
    }
  };
  const [profileLoader, setprofileLoader] = useState(false);
  useEffect(() => {
    setprofileLoader(true);
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actualToken}`,
      },
    };
    Axios.get(
      `${process.env.REACT_APP_URL}/users/publisher/profile/${userId}`,
      requestOptions
    ).then((data) => {
      setprofileLoader(false);
      if (data.data.success) {
        setuploadImage({
          loader: false,
          published_image: data.data.message.avaterUrl,
        });
      } else {
        setuploadImage({
          loader: false,
          published_image: "",
        });
      }
    });
  }, []);
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : userData ? (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          {profileLoader ? (
            <PageLoader />
          ) : (
            <div className="publisher__main__cover row m-0">
              <div className="col-md-2 col-sm-12 user__image__div__cover">
                <div className="update__label__and__div">
                  {uploadImage.loader ? (
                    <Spinner />
                  ) : (
                    <label htmlFor="profile__input__label">
                      {userData.avaterUrl !== undefined ||
                      uploadImage.published_image ? (
                        <img
                          src={
                            uploadImage.published_image
                              ? uploadImage.published_image
                              : userData.avaterUrl
                          }
                          alt="logo"
                          className="user__image__update__avaterUrl"
                        />
                      ) : (
                        <div className="user__image__update">
                          {userData.message.email.substring(0, 2).toUpperCase()}
                        </div>
                      )}
                    </label>
                  )}
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="profile__input__label"
                  name="file"
                  onChange={(evt) =>
                    handleUploadImage(evt.target.files[0], "published_image")
                  }
                ></input>
              </div>
              <div className="col input__cover__update">
                <UpdateFrom />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
