import React, { useEffect, useState } from "react";
import SideBar from "../../components/sidebar/SideBar";
import Header from "../../components/header/Header";
import PageLoader from "../../components/pageLoader/loader";
import PaymentTable from "./paymentTable";
import PaginationLink from "../myBooks/pagination";
import BookLogo from "../../images/edit.png";
import Payout from "../../images/wallet.png";
import Sales from "../../images/shopping-bag.png";
import Balance from "../../images/bank.png";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
export default function PayoutList() {
  const [recordLoader, setrecordLoader] = useState(false);
  const [myRecord, setmyRecord] = useState([]);
  const [payOutCount, setpayOutCount] = useState(0);
  const getAuthChecker = sessionStorage.getItem("userAuth");
  const [authExpire, setauthExpire] = useState(false);
  useEffect(() => {
    if (getAuthChecker === null || getAuthChecker === false) {
      window.location.href = "/signin";
    } else {
      setrecordLoader(true);
      const getTokens = JSON.parse(
        localStorage.getItem("persist:reducerStore")
      );
      const tokens = JSON.parse(getTokens.userReducer);
      const actualToken = tokens.credentials.token;
      const userData = tokens.credentials.message;
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      fetch(
        `${process.env.REACT_APP_URL}/publish/get/request/payment/${
          userData._id
        }?page=${1}&size=${10}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          setrecordLoader(false);

          if (data.success) {
            setmyRecord(data.message);
          } else {
            if (data.message === "jwt expired") {
              setauthExpire(true);
              sessionStorage.setItem(false);
            } else {
              setmyRecord(data.message);
            }
          }
        })
      );

      fetch(
        `${process.env.REACT_APP_URL}/publish/get/count/payout/publisher/${userData._id}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            const newCount = Math.ceil(data.message / 10);
            setpayOutCount(newCount);
          } else {
            setpayOutCount(0);
          }
        })
      );
    }
  }, []);
  const [payoutAmount, setpayoutAmount] = useState(0);
  const [salesAmount, setsalesAmount] = useState(0);

  useEffect(() => {
    const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
    const tokens = JSON.parse(getTokens.userReducer);
    const actualToken = tokens.credentials.token;
    const userData = tokens.credentials.message;
    if (getAuthChecker === null || getAuthChecker === false) {
      window.location.href = "/signin";
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      fetch(
        `${process.env.REACT_APP_URL}/publish/get/payment/amount/${userData._id}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            setpayoutAmount(data.message);
          } else {
            setpayoutAmount(0);
          }
        })
      );
      fetch(
        `${process.env.REACT_APP_URL}/order/get/order/amount/${userData._id}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          setrecordLoader(false);
          if (data.success) {
            setsalesAmount(data.message);
          } else {
            setsalesAmount(0);
          }
        })
      );
    }
  }, []);
  const [pageNumber, setpageNumber] = useState(1)
  const handleChange = (pageNumber) => {
    const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
    const tokens = JSON.parse(getTokens.userReducer);
    const actualToken = tokens.credentials.token;
    const userData = tokens.credentials.message;
    setrecordLoader(true);
    setpageNumber(pageNumber)
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/publish/get/request/payment/${
        userData._id
      }?page=${pageNumber}&size=${10}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        setrecordLoader(false);
        if (data.success) {
          setmyRecord(data.message);
        } else {
          setmyRecord(data.message);
        }
      })
    );
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          <div
            style={{
              padding: "30px",
              borderRadius: "6px",
              background: "white",
            }}
          >
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-md-4" style={{ marginBottom: "20px" }}>
                <div class="card ">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-4">
                        <img src={Payout} alt="logo" />
                      </div>
                      <div className="col">
                        <h6 style={{ fontWeight: "700", color: "#6019CF" }}>
                          Total Amount Payout
                        </h6>
                        <h6>${payoutAmount}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ marginBottom: "20px" }}>
                <div class="card ">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-4">
                        <img src={Sales} alt="logo" />
                      </div>
                      <div className="col">
                        <h6 style={{ fontWeight: "700", color: "#6019CF" }}>
                          Total Amount Sales
                        </h6>
                        <h6>${salesAmount ? salesAmount : "0"}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginBottom: "20px" }}>
                <div class="card ">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-4">
                        <img src={Balance} alt="logo" />
                      </div>
                      <div className="col">
                        <h6 style={{ fontWeight: "700", color: "#6019CF" }}>
                          Balance
                        </h6>
                        <h6>
                          $
                          {salesAmount > payoutAmount
                            ? salesAmount - payoutAmount
                            : 0}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {recordLoader ? (
            <PageLoader />
          ) : (
            <div className="publisher__main__cover row m-0">
              {myRecord.length ? (
                <div className="col-12">
                  {" "}
                  <div
                    className="header__books"
                    style={{ marginBottom: "20px", color: "#6019CF",fontWeight:"700" }}
                  >
                    Payment Record
                  </div>
                  <div style={{ height: "500px", overflowY: "scroll" }}>
                    <PaymentTable myRecord={myRecord} />
                  </div>
                  {/* <SalesTable mybooks={mybooks} /> */}
                  <PaginationLink
                    handleChange1={handleChange}
                    count={payOutCount}
                    pageNumberChange={pageNumber}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    paddingTop: "20%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img src={BookLogo} alt="book__logo" />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No sales found.
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
