import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import RenderPdfFile from "../../components/Drawer/renderPdfFile";
import Epub from "./epubReader";
import OtherReaders from "./otherReaders";


export default function Read() {
  const bookUrlReducer = useSelector((state) => state.bookReducer);
  const getBookUrl = bookUrlReducer.bookDetails
    ? bookUrlReducer.bookDetails.bookFile
    : "";
  // const getBookId = bookUrlReducer.bookDetails.bookId;
  const fileExtension = getBookUrl.split(".").pop();

  const history = useHistory();
  useEffect(() => {
    if (getBookUrl === "" || getBookUrl === undefined) {
      history.push("/books");
    }
  }, []);
  
  // <RenderPdfFile />
  return (
    <div>
      {getBookUrl ?fileExtension==="epub"? (
        <Epub
          bookUrl={getBookUrl}
          title={bookUrlReducer.bookDetails.title}
          bookId={bookUrlReducer.bookDetails.bookId}
        />
      ):<RenderPdfFile url={getBookUrl}  bookId={bookUrlReducer.bookDetails.bookId}  title={bookUrlReducer.bookDetails.title} />: (
        ""
      )}
    </div>
  );
}
