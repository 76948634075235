import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function PaymentTable({ myRecord }) {
  const classes = useStyles();

  //   const getBooks = mybooks
  //     ? mybooks.map((lists) => {
  //         return console.log(lists);
  //       })
  //     : "";
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Name</TableCell>

            <TableCell align="left">Account Number</TableCell>
            <TableCell align="left">Amount($)</TableCell>
            <TableCell align="left">Bank Name</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Request Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myRecord.length
            ? myRecord.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.accountName}
                  </TableCell>
                  <TableCell align="left">{row.accountNumber}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">{row.bankName}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">
                    {moment(row.createdAt).format("YYYY-MM-DD h:mm:ss a")}
                  </TableCell>
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
