import React from "react";
import emailjs from "emailjs-com";

export default function ContactUs() {
  const userID = "user_A8qlkmY4EyXXnBYpGidvu";
  const serviceId = "service_6vykfei";
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(serviceId, "YOUR_TEMPLATE_ID", e.target, userID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  }

  return (
    <form className="contact-form" onSubmit={sendEmail}>
      <input
        type="hidden"
        name="contact_number"
        className="form-control input__contact__fields"
      />

      <input
        type="text"
        name="user_name"
        className="form-control input__contact__fields"
        placeholder="Name"
      />

      <input
        type="email"
        name="user_email"
        className="form-control input__contact__fields"
        placeholder="Email"
      />

      <textarea
        name="message"
        className="form-control contact__us__message"
        rows="5"
        placeholder="Mesage"
      />
      <input type="submit" value="Send" className="contactus__button" />
    </form>
  );
}
