import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import RegisterPage from "./auth/registerFolder/RegisterPage";
import SignInPage from "./auth/signInFolder/SignInCover";
import { auth, firestore } from "./config/db";
import ContactUsCover from "./pages/contactus/ContactUsCover";
import DashboardCover from "./pages/Dashboard/DashboardCover";
import FaqPage from "./pages/faq/FaqPage";
import MyBookCover from "./pages/myBooks/booksCover";
import ProfileCover from "./pages/profile/profileCover";
import PublisherCover from "./pages/publishPage/PublisherCover";
import UpdateBookCover from "./pages/myBooks/updateBooks";
import SalesCover from "./pages/sales/salesCovevr";
import SalesDetails from "./pages/sales/salesDetails";
import { useSelector } from "react-redux";
import Read from "./pages/read/read";
import ForgotPasswordCover from "./auth/forgotPasswoed/ForgotPasswordCover";
import ChangePassword from "./auth/forgotPasswoed/changePassword";
import Axios from "axios";
import PayoutCover from "./pages/payout/payoutCover";
import PayoutList from "./pages/payout/payoutList";
require("dotenv").config();
function App() {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials;
  const history = useHistory();

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={DashboardCover}></Route>
          <Route path="/register" component={RegisterPage}></Route>
          <Route path="/signin" component={SignInPage}></Route>
          <Route path="/publish" component={PublisherCover}></Route>
          <Route path="/profile" component={ProfileCover}></Route>
          <Route path="/faq" component={FaqPage}></Route>
          <Route path="/contact" component={ContactUsCover}></Route>
          <Route path="/books" component={MyBookCover}></Route>
          <Route path="/sales" component={SalesCover}></Route>
          <Route path="/read" component={Read}></Route>
          <Route
            path="/forgot-password/:email"
            component={ChangePassword}
          ></Route>
          <Route
            path="/forgot-password"
            component={ForgotPasswordCover}
          ></Route>

          <Route
            path="/update/book/:productId"
            component={UpdateBookCover}
          ></Route>
          <Route path="/payout" component={PayoutCover}></Route>
          <Route path="/payment/details" component={PayoutList}></Route>

          {/* <Route path="/view/order/:userId" component={SalesDetails}></Route> */}
          {/* <Route path="/read/:bookLink" component={ExamplePDFViewer}></Route> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
