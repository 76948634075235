import React from "react";
import "../registerFolder/RegistrationPage.css";
import Logo from "../../images/cawadi-logo.png";
import ForgotPasswordForm from "./forgotPasswordForm";
export default function SignInPage() {
  return (
    <div className="regCover">
      <div className="reg__form__cover">
        <div className="cont__logo">
        <img src={Logo} alt="logo" className="logo__image" style={{objectFit:"contain",width:"120px"}}/>
         
        </div>
        <div className="sub__heading">
          Enter Email Address to recover password
        </div>
        <div className="container col-md-5 col-sm-12">
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}
