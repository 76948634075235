import firebase from "firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDN1t-fIu8wf8BUz441oTx2HS6Enp7lqWs",
  authDomain: "cawadi.firebaseapp.com",
  projectId: "cawadi",
  storageBucket: "cawadi.appspot.com",
  messagingSenderId: "392237857712",
  appId: "1:392237857712:web:5c0b26c3d7661372acf475",
  measurementId: "G-TKDMC3J4SQ",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
