const initState = {
   bookDetails:"",
  };
  const bookFileReducer = (state = { initState }, action) => {
    switch (action.type) {
      case "book_link":
        return {
         bookDetails: action.data,
        };
  
      default:
        return state;
    }
  };
  export default bookFileReducer;
  