import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import "./viewBook.css"
const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaginationControlled({ handleChange1, count,pageNumberChange, }) {
  const classes = useStyles();
const [page, setpage] = useState(1);
  const handleChangeP = (event, value) => {
   
    handleChange1(value);
    setpage(value);
   
  };
 
  return (
    <div className={classes.root} style={{ paddingTop: "30px" }}>
      <Pagination count={count} page={pageNumberChange} onChange={handleChangeP} />
    </div>
  );
}
