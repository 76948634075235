import React from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import "./contactus.css";
import ContactUs from "./contactUsForm";
export default function ContactUsCover() {
  return (
    <div>
      <div className="row dashboard__cover m-0">
        <div className="col-md-2 side__bar__cover">
          <SideBar />
        </div>
        <div className="col routerPage__cover">
          <Header />
          <div className="dashboard__cover">
            <div className="publisher__main__cover row m-0">
              <div className="col-md-5 col-sm-12 ">
                <div className="col-12 contact__us__header">Contact Us</div>
                <div className="contact__content__cover">
                  <p>
                    Do you have a question, some feedback or simply would like
                    to talk about all things books and literature, check out our
                    forum today - Join the conversation by clicking here
                  </p>
                  <p>
                    Use the contact form below to submit your message or leave
                    us a message on these emails: For sales, promotions and
                    marketing, book conversions and uploads: services@cawadi.com
                  </p>
                  <p>
                    For Technical issues with the app or website:
                    themechanic@cawadi books. com For issues with Withdrawing of
                    funds: Click here to resolve it
                  </p>
                  <p>
                    If you have issues verifying your email or changing the
                    price of your book then use the contact form below as well
                  </p>
                  <p>
                    For technical issues include your USERNAME, PHONE NAME and
                    MODEL in your message.
                  </p>
                </div>
              </div>
              <div className="col input__cover__update">
                <ContactUs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
