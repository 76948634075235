import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import RegisterPage from "../../auth/registerFolder/RegisterPage";
import Header from "../../components/header/Header";
import PageLoader from "../../components/pageLoader/loader";
import SideBar from "../../components/sidebar/SideBar";
import BookCardCover from "../../components/uploadBooksCard/BookCardCover";
import "./DashboardCover.css";
import BookLogo from "../../images/book-outline.png";
import Mychart from "./chart";
import Payout from "../../images/wallet.png";
import Sales from "../../images/shopping-bag.png";
import Balance from "../../images/bank.png";
import EpubPng from "../../images/epub.png";
import MenuIcon from "@material-ui/icons/Menu";
export default function DashboardCover() {
  const userReducer = useSelector((state) => state.userReducer);
  const publisherAuth = userReducer.credentials;

  const [mybooks, setmybooks] = useState([]);

  const [loader, setloader] = useState(false);
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const [authExpire, setauthExpire] = useState(false);
  const [totalPublishBook, settotalPublishBook] = useState(0);
  const [payOutCount, setpayOutCount] = useState(0);
  const [salesCount, setsalesCount] = useState(0);
  const getAuthChecker = sessionStorage.getItem("userAuth");
  const authChecker = async () => {
    if (getAuthChecker === null || getAuthChecker === false) {
      window.location.href = await "/signin";
    } else {
      const getTokens = await JSON.parse(
        localStorage.getItem("persist:reducerStore")
      );

      const tokens = await JSON.parse(getTokens.userReducer);

      const actualToken = await tokens.credentials.token;
      const userId = await tokens.credentials.message._id;
      setloader(true);
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      fetch(
        `${
          process.env.REACT_APP_URL
        }/publish/publisher/books/${userId}?page=${1}&size=${5}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          setloader(false);
          if (data.success) {
            // console.log(data.message);
            setmybooks(data.message);
          } else {
            if (data.message === "jwt expired") {
              setauthExpire(true);
              sessionStorage.setItem(false);
            }
            setmybooks([]);
          }
        })
      );
      fetch(
        `${process.env.REACT_APP_URL}/publish/get/count/publisher/${userId}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            settotalPublishBook(data.message);
          } else {
            settotalPublishBook(0);
          }
        })
      );

      fetch(
        `${process.env.REACT_APP_URL}/publish/get/count/payout/publisher/${userId}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            setpayOutCount(data.message);
          } else {
            setpayOutCount(0);
          }
        })
      );
      fetch(
        `${process.env.REACT_APP_URL}/order/get/count/order/publisher/${userId}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            setsalesCount(data.message);
          } else {
            setsalesCount(0);
          }
        })
      );
    }
  };
  useEffect(() => {
    authChecker();
  }, []);
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="row m-0 dashboard__cover">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col-md-10 routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover col-12">
          {loader ? (
            <div style={{paddingTop:"50%"}}>
              <PageLoader />
            </div>
          ) : (
            <div className="row m-0">
              <div
                className="col-md-12 col-sm-12 "
                style={{
                  background: "white",
                  paddingTop: "20px",
                  marginBottom: "20px",
                  borderRadius: "6px",
                }}
              >
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div
                    className="col-md-4 col-12"
                    style={{ marginBottom: "20px" }}
                  >
                    <div class="card ">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-4">
                            <img src={EpubPng} alt="logo" />
                          </div>
                          <div className="col">
                            <h6 style={{ fontWeight: "700", color: "#6b44d8" }}>
                              Total Number of Books
                            </h6>
                            <h6>{totalPublishBook ? totalPublishBook : "0"}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-12"
                    style={{ marginBottom: "20px" }}
                  >
                    <div class="card ">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-4">
                            <img src={Payout} alt="logo" />
                          </div>
                          <div className="col">
                            <h6 style={{ fontWeight: "700", color: "#6b44d8" }}>
                              Total Number of Payouts
                            </h6>
                            <h6>{payOutCount ? payOutCount : "0"}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-4 col-12"
                    style={{ marginBottom: "20px" }}
                  >
                    <div class="card ">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-4">
                            <img src={Sales} alt="logo" />
                          </div>
                          <div className="col">
                            <h6 style={{ fontWeight: "700", color: "#6b44d8" }}>
                              Total Number of book Sales
                            </h6>
                            <h6>{salesCount ? salesCount : "0"}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 book__cat">
                <h5
                  className="col-12"
                  style={{ fontWeight: "700", color: "#6b44d8" }}
                >
                  Recent Books
                </h5>
                {mybooks.length ? (
                  mybooks.map((book) => <BookCardCover details={{ ...book }} />)
                ) : (
                  <div
                    style={{
                      paddingTop: "40%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <img src={BookLogo} alt="book__logo" />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color:"#6b44d8"
                      }}
                    >
                      No book published
                    </div>
                  </div>
                )}
                <div className="see__more">
                  {mybooks.length ? (
                    <Link to="/books">
                      <Button className="show__more__btn">Show More</Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div
                className="col-md col-sm-12 slaes__chat"
                style={{
                  background: "white",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "50px",
                    fontWeight: "700",
                    color: "#1941cf",
                  }}
                >
                  Books In Store
                </div>
                <Mychart />
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
