import React, { useState } from "react";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import TuneIcon from "@material-ui/icons/Tune";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function RoutePages() {
  const history = useHistory();
  const [Routes, setRoutes] = useState({
    pages: [
      {
        id: 1,
        name: "Dashboard",
        icon: <DashboardIcon className="list__icon" />,
        route: "/",
      },
      {
        id: 2,
        name: "Sales",
        icon: <ReceiptIcon className="list__icon" />,
        route: "/sales",
      },
      {
        id: 15,
        name: "Request Payout",
        icon: <ReceiptIcon className="list__icon" />,
        route: "/payout",
      },
      {
        id: 17,
        name: "Payment Record",
        icon: <ReceiptIcon className="list__icon" />,
        route: "/payment/details",
      },
      {
        id: 3,
        name: "Publish",
        icon: <MailOutlineIcon className="list__icon" />,
        route: "/publish",
      },
      {
        id: 11,
        name: "My books",
        icon: <LibraryBooksIcon className="list__icon" />,
        route: "/books",
      },
      {
        id: 4,
        name: "Profile",
        icon: <PersonOutlineIcon className="list__icon" />,
        route: "/profile",
      },

      // {
      //   id: 5,
      //   name: "FAQ",
      //   icon: <ChatBubbleOutlineIcon className="list__icon" />,
      //   route: "/faq",
      // },
      // {
      //   id: 6,
      //   name: "Contact Us",
      //   icon: <RecentActorsIcon className="list__icon" />,
      //   borderBottom: "1px solid #EBEFF2",
      //   paddingBottom: "24px",
      //   route: "/contact",
      // },
      {
        id: 7,
        name: "Logout",
        icon: <ExitToAppIcon className="list__icon" />,
      },
      {
        id: 8,
        name: "Toggle Sidebar",
        icon: <TuneIcon className="list__icon" />,
      },
    ],
  });
  const dispatch = useDispatch();
  const handleRouteName = (name) => {
    const getRouteClick = Routes.pages.find((route) => route.name === name);
    // console.log(getRouteClick);
    if (getRouteClick) {
      const getNewData = Routes.pages.map((routes) =>
        routes.name === getRouteClick.name
          ? { ...routes, color: "#1941cf" }
          : { ...routes }
      );
      setRoutes({ pages: getNewData });
    }
  
    if (name === "Logout") {
      window.location.href = "/signin";
      dispatch({ type: "user_details", data: null });
      sessionStorage.setItem(false);
    }
  };
 
  const getRoutes = Routes
    ? Routes.pages.map((list) => (
        <Link to={list.route}>
          {" "}
          <li
            key={list.id}
            className="route__list"
            style={{
              borderBottom: list.borderBottom,
              paddingBottom: list.paddingBottom,
              color: list.color,
            }}
            onClick={() => handleRouteName(list.name)}
          >
            <span>{list.icon}</span>
            {list.name}
          </li>
        </Link>
      ))
    : "";
  return (
    <div>
      <ul>{getRoutes}</ul>
    </div>
  );
}
