import React from "react";
import "../registerFolder/RegistrationPage.css";
import Logo from "../../images/cawadi-logo.png";
import RegistrationForm from "../registerFolder/RegistrationForm";
import SignInForm from "./SignInForm";
export default function SignInPage() {
  return (
    <div className="regCover">
      <div className="reg__form__cover">
        <div className="cont__logo">
        <img src={Logo} alt="logo" className="logo__image" style={{objectFit:"contain",width:"120px"}}/>
          {/* <div className="site__name"> Cawadi</div> */}
        </div>
        <div className="sub__heading">Sign in to your account to continue</div>
        <div className="container col-md-5 col-sm-12">
          <SignInForm />
        </div>
      </div>
    </div>
  );
}
