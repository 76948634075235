import React from "react";
import "./RegistrationPage.css";
import Logo from "../../images/cawadi-logo.png";
import RegistrationForm from "./RegistrationForm";
export default function RegisterPage() {
  return (
    <div className="regCover">
      <div className="reg__form__cover">
        <div className="cont__logo">
          <img src={Logo} alt="logo" className="logo__image" style={{objectFit:"contain",width:"120px"}}/>
         
        </div>
        <div className="sub__heading">Please create a new account here</div>
        <div className="container col-md-4 col-sm-12">
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
}
