import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import BookTable from "./bookTable";
import OrderTable from "./orderTable";
import BuyerTable from "./buyerTable";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function SalesDetails({ salesDetails }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      style={{
        height: "100vh",
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "20px",
        }}
      >
        <Button
          style={{ background: "#6019CF", color: "white" }}
          onClick={toggleDrawer(anchor, false)}
        >
          {" "}
          Close
        </Button>
      </div>
      <div>
        <div
          style={{ marginBottom: "20px", fontWeight: "700", color: "#6019CF" }}
        >
          {" "}
          Book Details
        </div>
        <BookTable mybooks={{ ...salesDetails.bookId }} />
      </div>
      <div>
        <div
          style={{
            marginBottom: "20px",
            fontWeight: "700",
            marginTop: "20px",
            color: "#6019CF",
          }}
        >
          {" "}
          Payment Details
        </div>
        <OrderTable orderDetails={{ ...salesDetails }} />
      </div>
      <div>
        <div
          style={{
            marginBottom: "20px",
            fontWeight: "700",
            marginTop: "20px",
            color: "#6019CF",
          }}
        >
          {" "}
          Buyer Details
        </div>
        <BuyerTable buyerDetails={{ ...salesDetails.buyerId }} />
      </div>
    </div>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{ background: "#6019CF", color: "white" }}
            onClick={toggleDrawer(anchor, true)}
          >
            view
          </Button>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
