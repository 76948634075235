import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ISBNNumber from "../../images/bar-code.png";
toast.configure();
export default function ISBNPayment({
  publisherName,
  reloadPublisher,
  userid,
}) {
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const [details, setdetails] = useState({
    description: "",
    amount: 0,
    name: "",
  });
  useEffect(() => {
    setdetails({
      name: publisherName,
      description: "Payment for ISBN Number",
      amount: 30 * 100,
    });
  }, []);

  const handleToken = async (token) => {
    // const requestOptions = {
    //     headers: {
    //       Authorization: `Bearer ${actualToken}`,
    //     },
    //   };

    const response = await Axios.post(
      `${process.env.REACT_APP_URL}/publish/checkout/isbn/${userid}`,
      {
        details,
        token,
      }
    );
    if (response.data.success) {
      reloadPublisher(true);

      toast("Success! Payment successful", {
        type: "success",
      });
    } else {
      toast("Something went wrong", {
        type: "error",
      });
    }

    console.log({ tokenData: response });
  };

  return (
    <div class="card container col-md-4 col-12" style={{ marginTop: "40px" }}>
      <div class="card-body">
        <h6
          style={{
            width: "100%",
            textAlign: "center",
            color: "#4627CF",
            paddingTop: "20px",
            fontWeight: "700",
          }}
        >
          Proceed to payment to get ISBN for your publish.
        </h6>
        <div style={{ textAlign: "center", width: "100%" }}>
          <img src={ISBNNumber} alt="isbn_logo" />
        </div>
        <div
          style={{
            marginBottom: "30px",
            marginTop: "20px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <StripeCheckout
            stripeKey="pk_test_onqdk46hblGVFXkqXst8jODS00KUSdlR0J"
            token={handleToken}
            amount={details.amount}
          />
        </div>
      </div>
    </div>
  );
}
