import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleAlerts from "../../components/alert/Alert";

export default function UpdateFrom() {
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials.message;
  const token = userReducer.credentials.token;
  if (userData === null || token.initState) {
    window.location.href = "/signin";
  }
  const [publisherDetails, setpublisherDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    location: "",
    country: "",
    canPublishBook: "",
  });
  const [loader, setloader] = useState({
    loaderMessage: false,
    message: "",
    status: "",
  });
  const handleUpdate = (value, attr) => {
    setpublisherDetails({
      ...publisherDetails,
      [attr]: value,
    });
  };
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/profile/${userData._id}`,
      requestOptions
    ).then((res) => {
      res.json().then((data) => {
       
        if (data.success) {
          setpublisherDetails({
            firstname: data.message.firstName,
            lastname: data.message.lastName,
            email: data.message.email,
            companyName: data.message.companyName,
            location: data.message.location,
            country: data.message.country,
            phoneNumber: data.message.phoneNumber,
            canPublishBook: data.message.canPublishBook,
          });
        }
      });
    });
  }, []);
  const handleSubmit = () => {
    setloader({
      ...loader,
      loaderMessage: true,
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        firstName: publisherDetails.firstname,
        lastName: publisherDetails.lastname,
        email: publisherDetails.email,
        phoneNumber: publisherDetails.phoneNumber,
        companyName: publisherDetails.companyName,
        location: publisherDetails.location,
        country: publisherDetails.country,
        canPublishBook: publisherDetails.canPublishBook,
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/profile/${userData._id}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          setloader({
            message: "Your profile has been Successfully Updated",
            status: "success",
            loaderMessage: false,
          });
          setpublisherDetails({
            firstname: data.message.firstName,
            lastname: data.message.lastName,
            email: data.message.email,
            phoneNumber: data.message.phoneNumber,
            companyName: data.message.companyName,
            location: data.message.location,
            country: data.message.country,
            canPublishBook: data.message.canPublishBook,
          });
        } else {
          setloader({
            message: data.message,
            status: "error",
            loaderMessage: false,
          });
        }
      })
    );
  };

  return (
    <div>
      {loader.message ? (
        <div className="alert__message__div__cover">
          <SimpleAlerts details={{ ...loader }} />
        </div>
      ) : (
        ""
      )}
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="First name"
        onChange={(evt) => handleUpdate(evt.target.value, "firstname")}
        value={publisherDetails.firstname}
      />
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Last name"
        onChange={(evt) => handleUpdate(evt.target.value, "lastname")}
        value={publisherDetails.lastname}
      />
      {/* <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Email"
        onChange={(evt) => handleUpdate(evt.target.value, "email")}
        value={publisherDetails.email}
        disabled
      /> */}
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Phone number"
        onChange={(evt) => handleUpdate(evt.target.value, "phoneNumber")}
        value={publisherDetails.phoneNumber}
      />
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Company name"
        onChange={(evt) => handleUpdate(evt.target.value, "companyName")}
        value={publisherDetails.companyName}
      />
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Location"
        onChange={(evt) => handleUpdate(evt.target.value, "location")}
        value={publisherDetails.location}
      />
      <input
        type="text"
        className="form-control update__input__from__profile"
        placeholder="Country"
        onChange={(evt) => handleUpdate(evt.target.value, "country")}
        value={publisherDetails.country}
      />
      {loader.loaderMessage ? (
        <Button className="update__save__button">Please wait...</Button>
      ) : (
        <Button className="update__save__button" onClick={handleSubmit}>
          Save
        </Button>
      )}
    </div>
  );
}
