import React, { useEffect, useState } from "react";
import SideBar from "../../components/sidebar/SideBar";
import Header from "../../components/header/Header";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SimpleAlerts from "../../components/alert/Alert";
import "./payout.css"
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
export default function PayoutCover() {
  const [sales, setsales] = useState(0);
  const [payout, setpayout] = useState(0);
  const [reload, setreload] = useState(false);
  const [authExpire, setauthExpire] = useState(false);
  const [formDetails, setformDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    amount: "",
    publisherId: "",
    publisherName: "",
    publisherEmail: "",
    publisherPhoneNumber: "",
  });
  const [loader, setloader] = useState({
    loaderMessage: false,
    message: "",
    status: "",
  });
  const handleChange = (value, attr) => {
    setformDetails({
      ...formDetails,
      [attr]: value,
    });
  };
  const getAuthChecker = sessionStorage.getItem("userAuth");

  useEffect(() => {
    if (getAuthChecker === null || getAuthChecker === false) {
      window.location.href = "/signin";
    } else {
      const getTokens = JSON.parse(
        localStorage.getItem("persist:reducerStore")
      );
      const tokens = JSON.parse(getTokens.userReducer);
      const actualToken = tokens.credentials.token;
      const userData = tokens.credentials.message;
      setformDetails({
        ...formDetails,
        publisherId: userData._id,
        publisherName: userData.firstName + " " + userData.lastName,
        publisherEmail: userData.email,
        publisherPhoneNumber: userData.phoneNumber,
      });
      const requestOptions1 = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      fetch(
        `${process.env.REACT_APP_URL}/publish/get/payment/amount/${userData._id}`,
        requestOptions1
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            setpayout(parseInt(data.message));
          } else {
            if (data.message === "jwt expired") {
              setauthExpire(true);
              sessionStorage.setItem(false);
            } else {
              setsales(0);
            }
          }
        })
      );
      fetch(
        `${process.env.REACT_APP_URL}/order/get/order/amount/${userData._id}`,
        requestOptions1
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            setsales(parseInt(data.message));
          } else {
            setsales(0);
          }
        })
      );
    }
  }, [reload]);

  const handleSubmit = () => {
    const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
    const tokens = JSON.parse(getTokens.userReducer);
    const actualToken = tokens.credentials.token;
    const userData = tokens.credentials.message;
    setloader({
      loaderMessage: true,
    });
    if (
      formDetails.bankName === "" ||
      formDetails.accountName === "" ||
      formDetails.accountNumber === "" ||
      formDetails.amount === "" ||
      formDetails.publisherEmail === ""
    ) {
      setloader({
        loaderMessage: false,
        status: "error",
        message: "Please fill out all empty fields",
      });
      setformDetails({
        bankName: "",
        accountName: "",
        accountNumber: "",
        amount: "",
        publisherId: "",
        publisherName: "",
        publisherEmail: "",
        publisherPhoneNumber: "",
      });
    } else if (sales - payout < parseInt(formDetails.amount)) {
      setloader({
        loaderMessage: false,
        status: "error",
        message:
          "The amount you request for is higher than your current balance",
      });
      setformDetails({
        bankName: "",
        accountName: "",
        accountNumber: "",
        amount: "",
        publisherId: "",
        publisherName: "",
        publisherEmail: "",
        publisherPhoneNumber: "",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${actualToken}`,
        },
        body: JSON.stringify({
          bankName: formDetails.bankName,
          accountName: formDetails.accountName,
          accountNumber: formDetails.accountNumber,
          amount: formDetails.amount,
          publisherId: formDetails.publisherId,
          publisherName: formDetails.publisherName,
          publisherEmail: formDetails.publisherEmail,
          publisherPhoneNumber: formDetails.publisherPhoneNumber,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/publish/request/payout/${formDetails.publisherId}`,
        requestOptions
      ).then((res) => {
        res.json().then((result) => {
          if (result.success) {
            setloader({
              loaderMessage: false,
              status: "success",
              message: result.message,
            });
            setformDetails({
              bankName: "",
              accountName: "",
              accountNumber: "",
              amount: "",
              publisherId: "",
              publisherName: "",
              publisherEmail: "",
              publisherPhoneNumber: "",
            });
          } else {
            setloader({
              loaderMessage: false,
              status: "error",
              message: result.message,
            });
            setformDetails({
              bankName: "",
              accountName: "",
              accountNumber: "",
              amount: "",
              publisherId: "",
              publisherName: "",
              publisherEmail: "",
              publisherPhoneNumber: "",
            });
          }
        });
      });
    }
    if (reload) {
      setreload(false);
    } else {
      setreload(true);
    }
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          <div
            className="container col-md-5 col-12"
            style={{ paddingBottom: "50px" }}
          >
            <div class="card">
              <div class="card-body" style={{ paddingTop: "50px" }}>
                <div
                  style={{
                    fontWeight: "700",
                    color: "black",
                    marginBottom: "20px",
                  }}
                >
                  Balance: ${sales ? sales - payout : "0"}
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    textAlign: "center",
                    fontWeight: "700",
                    color: "#6019CF",
                  }}
                >
                  Request Payment Form
                </div>
                {loader.message ? (
                  <div style={{ marginBottom: "20px" }}>
                    {" "}
                    <SimpleAlerts details={{ ...loader }} />{" "}
                  </div>
                ) : (
                  ""
                )}
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="text"
                    className="form-control update__input__from__profile"
                    placeholder="Bank Name"
                    value={formDetails.bankName}
                    onChange={(evt) =>
                      handleChange(evt.target.value, "bankName")
                    }
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="text"
                    className="form-control update__input__from__profile"
                    placeholder="Account Name"
                    onChange={(evt) =>
                      handleChange(evt.target.value, "accountName")
                    }
                    value={formDetails.accountName}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="text"
                    className="form-control update__input__from__profile"
                    placeholder="Account Number"
                    onChange={(evt) =>
                      handleChange(evt.target.value, "accountNumber")
                    }
                    value={formDetails.accountNumber}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="number"
                    className="form-control update__input__from__profile"
                    placeholder="Amount($)"
                    onChange={(evt) => handleChange(evt.target.value, "amount")}
                    value={formDetails.amount}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  {loader.loaderMessage ? (
                    <Button className="update__save__button">
                      Please wait...
                    </Button>
                  ) : (
                    <Button
                      className="update__save__button"
                      onClick={handleSubmit}
                    >
                      Request
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
