import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
class DeleteAlert extends React.Component {
  DeleteBook = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.actualToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/publish/${this.props.productId}`,
      requestOptions
    )
      .then((data) => {
        data.json().then((result) => {
          if (result.success) {
            alert(result.message);
            window.location.href = "/books";
          } else {
            alert(result.message);
          }
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.DeleteBook(),
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  render() {
    return (
      <div>
        <button
          onClick={this.submit}
          className="delet__book"
          style={{
            backgroundColor: "red",
            color: "white",
            border: "none",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "50%",
            borderRadius: "26px",
          }}
        >
          Delete Book
        </button>
      </div>
    );
  }
}
export default DeleteAlert;
