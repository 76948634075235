import React, { useEffect, useState } from "react";
import "./SideBar.css";
import Logo from "../../images/cawadi-logo.png";
import ImageAvatars from "./avaterDiv";
import RoutePages from "./RoutePages";
import { useSelector } from "react-redux";
export default function SideBar({ handAndShow }) {
  const userReducer = useSelector((state) => state.userReducer);
  const getUserDetails = userReducer.credentials;

  const [avaterUrl, setavaterUrl] = useState("");
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));

  useEffect(() => {
    if (userReducer === null) {
      window.location.href = "/signin";
    } else {
      const tokens = JSON.parse(getTokens.userReducer);
      if (getUserDetails === null || tokens.credentials === null) {
        window.location.href = "/signin";
      } else {
        const actualToken = tokens.credentials.token;
        const userId = tokens.credentials.message._id;
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${actualToken}`,
          },
        };
        fetch(
          `${process.env.REACT_APP_URL}/users/publisher/profile/${userId}`,
          requestOptions
        ).then((res) => {
          res.json().then((data) => {
            if (data.success) {
              // console.log(data.message);
              setavaterUrl(data.message.avaterUrl);
            } else {
              setavaterUrl("");
            }
          });
        });
      }
    }
  }, []);
  // console.log(avaterUrl);
  return (
    <div>
      {getUserDetails ? (
        <div
          className="sidebar__div"
          style={{
            display: handAndShow ? "block" : "none",
            transition: "0.3s",
          }}
        >
          <div className="logo__header">
            <img
              src={Logo}
              alt="logo"
              style={{ objectFit: "contain", width: "120px" }}
            />
            {/* <span className="name">Cawadi</span> */}
          </div>
          <div className="avater__div">
            <div className="row user__and__image__row">
              <div className="col-3 user__image">
                <ImageAvatars avaterUrl={avaterUrl} />
              </div>
              <div className="col name__and__email">
                <div className="user__name ">
                  {getUserDetails
                    ? getUserDetails.message.firstName.charAt(0).toUpperCase() +
                      getUserDetails.message.firstName.substring(1)
                    : "N/A"}
                </div>
                <div className="user__email ">
                  {getUserDetails
                    ? getUserDetails.message.email.length > 15
                      ? getUserDetails.message.email.substring(0, 15) + "..."
                      : getUserDetails.message.email
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div>
            <RoutePages />
          </div>
        </div>
      ) : (
        ""
      )}
      {getUserDetails ? (
        <div
          className="sidebar__divBig"
          style={{
            display: handAndShow ? "block" : "none",
            transition: "0.3s",
          }}
        >
          <div className="logo__header">
            <img
              src={Logo}
              alt="logo"
              style={{ objectFit: "contain", width: "120px" }}
            />
            {/* <span className="name">Cawadi</span> */}
          </div>
          <div className="avater__div">
            <div className="row user__and__image__row">
              <div className="col-3 user__image">
                <ImageAvatars avaterUrl={avaterUrl} />
              </div>
              <div className="col name__and__email">
                <div className="user__name ">
                  {getUserDetails
                    ? getUserDetails.message.firstName.charAt(0).toUpperCase() +
                      getUserDetails.message.firstName.substring(1)
                    : "N/A"}
                </div>
                <div className="user__email ">
                  {getUserDetails
                    ? getUserDetails.message.email.length > 15
                      ? getUserDetails.message.email.substring(0, 15) + "..."
                      : getUserDetails.message.email
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div>
            <RoutePages />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
