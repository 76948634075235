import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Link } from "react-router-dom";

export default function SimpleCard({ details }) {
  return (
    <Card className="book__cards">
      <CardContent className="card__content">
        <div className="row card__row">
          <div className="col-md-9 col-12 title">{details.title}</div>
          <div className="col status">
            {moment(details.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
          <div className="col-12 cat">
            <span className="cat__span">Book Category: </span>
            {details.category.name}
          </div>
          <div className="col-9 auth__name">
            <span className="cat__span">Aurthor: </span>
            {details.author}
          </div>
          <div className="col button__div">
            <Link to={"/update/book/" + details._id}>
              <Button className="view__more">View</Button>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
