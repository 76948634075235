import React from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import "./faq.css";
import FaqCollapse from "./FaqCollapse";
export default function FaqPage() {
  const userReducer = useSelector((state) => state.userReducer);
  const getUser = userReducer.credentials;
  return getUser ? (
    <div className="row m-0 dashboard__cover">
      <div className="col-md-2 side__bar__cover">
        <SideBar />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="dashboard__cover faq__cover">
          <div className="faq__header">FAQs</div>
          <div>
            <FaqCollapse />
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
