import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Player from "./audioPlayer";
import { useState } from "react";
import Axios from "axios";
import SimpleAlerts from "../../components/alert/Alert";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ handleUpdateFile, stateReloader }) {
  const [open, setOpen] = React.useState(false);
  const [updateTitle, setupdateTitle] = useState(handleUpdateFile.title);
  const [loadState, setloadState] = useState(false);
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const [loader, setloader] = useState(false);
  const [messageDetails, setmessageDetails] = useState({
    status: "",
    message: "",
  });
  const [uploadAudioFile, setuploadAudioFile] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setmessageDetails({
      status: "",
      message: "",
    });
  };
  const handleUpdateTitle = (value) => {
    setupdateTitle(value);
  };
  const handleUpdateTitleBtn = () => {
    setloader(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${actualToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        title: updateTitle,
      }),
    };

    fetch(
      `${process.env.REACT_APP_URL}/publish/rich/update/audio/title/${handleUpdateFile._id}`,
      requestOptions
    ).then((res) => {
      if (loadState) {
        setloadState(false);
      } else {
        setloadState(true);
      }
      res.json().then((result) => {
        setloader(false);
        if (result.success) {
          setmessageDetails({
            status: "success",
            message: "Audio title updated successful",
          });
        } else {
          setmessageDetails({
            status: "error",
            message: "An error occur please try again.",
          });
        }
      });
    });
  };
  const handleShortAudioUpload = (value, attr) => {
    const fileName = value.name;

    const fileExtension = fileName.split(".").pop();
    if (fileExtension === "mp3") {
      setuploadAudioFile(value);
      setmessageDetails("");
    } else {
      setuploadAudioFile("");
      setmessageDetails({
        status: "error",
        message: "The file you enter is not supported.",
      });
      alert("the file you upload is not supported");
      return;
    }
  };
  const handleUploadRichAudioButton = () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    setloader(true);
    const formData = new FormData();
    formData.append("file", uploadAudioFile);

    Axios.put(
      `${process.env.REACT_APP_URL}/publish/rich/update/audio/${handleUpdateFile._id}`,
      formData,
      requestOptions
    )
      .then((res) => {
        if (loadState) {
          setloadState(false);
        } else {
          setloadState(true);
        }
        if (res.data.success) {
          setloader(false);
          setuploadAudioFile("");
          setmessageDetails({
            status: "success",
            message: "Audio  updated successful",
          });
        } else {
          setloader(false);
          setuploadAudioFile("");
          setmessageDetails({
            status: "error",
            message: "An error occur please try again",
          });
        }
      })
      .catch((error) => {
        setmessageDetails({
          status: "error",
          message: "An error occur please try again.",
        });
      });
  };

  useEffect(() => {
    stateReloader(loadState);
  }, [loadState]);
  return (
    <div>
      <VisibilityIcon style={{ color: "#4429CF" }} onClick={handleClickOpen} />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Update File"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ width: "400px" }}>
              <Player url={handleUpdateFile.file} />
              <div style={{ marginTop: "20px" }}>
                {messageDetails === "" ? (
                  ""
                ) : (
                  <div style={{ marginBottom: "10px" }}>
                    <SimpleAlerts details={messageDetails} />
                  </div>
                )}

                <label style={{ color: "#6019CF", fontWeight: "700" }}>
                  Audio Title
                </label>
                <input
                  type="text"
                  className="published__input"
                  placeholder=""
                  value={updateTitle}
                  onChange={(evt) => handleUpdateTitle(evt.target.value)}
                />
                {loader ? (
                  <Button
                    style={{
                      background: "#6019CF",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderRadius: "26px",
                      color: "white",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    Please wait...
                  </Button>
                ) : (
                  <Button
                    style={{
                      background: "#6019CF",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderRadius: "26px",
                      color: "white",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      marginBottom: "20px",
                    }}
                    onClick={handleUpdateTitleBtn}
                  >
                    Update Title
                  </Button>
                )}
                <input
                  type="file"
                  id="upload_pdf_cover"
                  style={{ width: "100%" }}
                  onChange={(evt) =>
                    handleShortAudioUpload(evt.target.files[0])
                  }
                  style={{ marginTop: "20px" }}
                />
                {uploadAudioFile ? (
                  loader ? (
                    <Button className="save__button">Please wait...</Button>
                  ) : (
                    <Button
                      className="save__button"
                      onClick={handleUploadRichAudioButton}
                    >
                      Upload Audio
                    </Button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
