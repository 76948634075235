import React, { Component } from "react";
import {
  ReactReader,
  EpubView,
  EpubViewStyle,
  ReactReaderStyle,
} from "react-reader";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import { EpubViewer, ReactEpubViewer } from "react-epub-viewer";
const Epub = ({ bookUrl, title, bookId }) => {
  //   const bookUrlReducer = useSelector((state) => state.bookUrlReducer);
  //   const bookurl = bookUrlReducer.bookUrl.bookUrl;
  //   const bookTitle = bookUrlReducer.bookUrl.bookTitle;
  //   const getHttps = bookurl.substring(4, 5);

  //   const bookSecUrl =
  //     getHttps === "s"
  //       ? bookurl
  //       : bookurl.substring(0, 4) + "s" + bookurl.substring(4);

  const viewerRef = useRef(null);
  console.log({ bookUrl });
  return (
    <div>
      <div
        style={{ position: "absolute", zIndex: "1000", top: "2%", left: "90%" }}
      >
        <Link to={"/update/book/" + bookId}>
          {" "}
          <Button className="back__button">Close</Button>
        </Link>
      </div>
      <div style={{ position: "relative", height: "100vh" }}>
        {/* <EpubView
          url={bookUrl}
          locationChanged={(epubcifi) => console.log(epubcifi)}
          tocChanged={(toc) => console.log(toc)}
        /> */}
        <ReactReader url={bookUrl} title={title} showToc={true} />
      </div>
    </div>

    //
  );
};
export default Epub;
