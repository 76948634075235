import { applyMiddleware, createStore } from "redux";
import reduxLogger from "redux-logger";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const getLogger = [reduxLogger];
const persistConfig = {
  key: "reducerStore",
  storage: storage,
  whitelist: ["userReducer"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, applyMiddleware(...getLogger));
const persistor = persistStore(store);

export default store;
