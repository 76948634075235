import { Button } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import { PDFViewer } from 'react-view-pdf';
import "./pdf.css";
export default function RenderPdfFile({url,bookId,title}) {


  return (
    <div >
      <div className="pdf__header row m-0"> 
     
        <div className="col" style={{textAlign:"center"}}>
         
          {title}
        </div>
        <div className="col-md-1 col-2"> <Link to={"/update/book/" + bookId}>
          {" "}
          
          <Button className="back__button">Close</Button>
        </Link></div>
     </div>
     <div className="pdf__body">
     <PDFViewer url={url} />
     </div>
    </div>
  )
}
