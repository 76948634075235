import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Notification() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Badge badgeContent={4} color="secondary">
        <NotificationsNoneIcon
          className="notfication__icon"
          style={{ fontSize: "18px", color: "#C2CFE0" }}
        />
      </Badge>
    </div>
  );
}
