import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";

import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialogSlide from "./updateModal";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function AudioList({ details, stateReloaderC,index }) {
 
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const stateReloader = (data) => {
    stateReloaderC(data);
  };
  const [checkDeleteState, setcheckDeleteState] = useState("");
  const deleteAudio = (id) => {
    stateReloaderC(false);
    setcheckDeleteState(id);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/publish/rich/delete/audio/${details._id}`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        stateReloaderC(true);
        if (result.success) {
          setcheckDeleteState("");
        } else {
          setcheckDeleteState("");
        }
      });
    });

  
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className={classes.demo}>
            <List dense={dense}>
              <ListItem>
               
                <ListItemAvatar>
                  <Avatar style={{ background: "#6019CF" }}>
                    <AudiotrackIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={details.title}
                  secondary={secondary ? "Secondary text" : null}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    style={{ marginRight: "5px" }}
                  >
                    <AlertDialogSlide
                      handleUpdateFile={details}
                      stateReloader={stateReloader}
                    />
                  </IconButton>
                  {checkDeleteState === details._id ? (
                    "loading..."
                  ) : (index===0? <IconButton
                    edge="end"
                    aria-label="delete"

                   
                  >
                    <DeleteIcon style={{ color: "silver" }} />
                  </IconButton>:
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteAudio(details._id)}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
