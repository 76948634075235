import React from "react";
import { Button } from "@material-ui/core";
import { useState } from "react";
import SimpleAlerts from "../../components/alert/Alert";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
export default function ForgotPasswordForm() {
  const [email, setemail] = useState("");
  const handleChange = (email) => {
    setemail(email);
  };
  const [loader, setloader] = useState(false);
  const [messageDetails, setmessageDetails] = useState({
    message: "",
    status: "",
  });
  const history = useHistory();
  const handleSendCode = () => {
    setloader(true);
    if (email === "") {
      setloader(false);
      return setmessageDetails({
        message: "Please enter an email address",
        status: "error",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      };

      fetch(
        `${process.env.REACT_APP_URL}/users/publisher/changePassword`,
        requestOptions
      ).then((res) => {
        res.json().then((result) => {
          if (result.success) {
            setloader(false);
            setemail("");
            alert("verification code has been sent to your email");
            history.push("/forgot-password/" + email);

            // return setmessageDetails({
            //   message:result.message,
            //   status:"success"
            // })
          } else {
            setemail("");

            setloader(false);
            return setmessageDetails({
              message: result.message,
              status: "error",
            });
          }
        });
      });
    }
  };
  return (
    <div>
      <div className="form-group col-md-12 col-sm-12 form__div">
        {messageDetails.message !== "" ? (
          <div style={{ paddingBottom: "20px" }}>
            <SimpleAlerts details={messageDetails} />
          </div>
        ) : (
          ""
        )}
        <input
          type="email"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Email Address"
          value={email}
          onChange={(evt) => handleChange(evt.target.value)}
        />
      </div>
      <div className="button__div col-12">
        <div style={{ width: "100%" }}>
          {loader ? (
            <Button className="sign__up__button">Please wait...</Button>
          ) : (
            <Button className="sign__up__button" onClick={handleSendCode}>
              Send
            </Button>
          )}
          <div
            style={{
              fontSize: "15px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "10px",
            }}
          >
            back to{" "}
            <Link to="/signin" style={{ marginLeft: "10px" }}>
              sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
