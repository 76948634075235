import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import SalesDetails from "./salesDetails";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function SalesTable({ mybooks }) {
  const classes = useStyles();
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const userId = tokens.credentials.message._id;

  //   const getBooks = mybooks
  //     ? mybooks.map((lists) => {
  //         return console.log(lists);
  //       })
  //     : "";
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>

            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Publisher percentage</TableCell>
            <TableCell align="left">Transaction type</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mybooks.length
            ? mybooks.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.bookId.title.length > 30
                      ? row.bookId.title.substring(0, 30) + "..."
                      : row.bookId.title}
                  </TableCell>
                  <TableCell align="left">{"$" + row.bookId.price}</TableCell>
                  <TableCell align="left">
                    {row.publisherAmount ? (
                      <div>
                        ${row.publisherAmount}
                        {row.status === "pending" ? (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "700",
                              marginLeft: "5px",
                              fontSize: "10px",
                            }}
                          >
                            (Can Payout)
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "green",
                              fontWeight: "700",
                              marginLeft: "5px",
                              fontSize: "10px",
                            }}
                          >
                            (Can Payout)
                          </span>
                        )}
                      </div>
                    ) : (
                      "-"
                    )}
                  </TableCell>

                  <TableCell align="left">{row.paymentType}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">
                    <SalesDetails salesDetails={{ ...row }} />
                  </TableCell>
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
