import { Button } from "@material-ui/core";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link, useHistory } from "react-router-dom";
import SimpleAlerts from "../../components/alert/Alert";
import { auth, firestore } from "../../config/db";
import { useDispatch } from "react-redux";
const RegistrationForm = () => {
  const history = useHistory();
  const [Values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    password: "",
    rpassword: "",
  });
  const handleChange = (value, attr) => {
    setValues({
      ...Values,
      [attr]: value,
    });
  };
  const [sendMessage, setsendMessage] = useState({
    message: "",
    status: "",
    loader: false,
  });
  const [loader, setloader] = useState(false);
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const dispatch = useDispatch();
  const handleSubmit = () => {
    setsendMessage({
      loader: true,
    });
    if (
      Values.firstname === "" ||
      Values.lastname === "" ||
      Values.email === "" ||
      Values.country === "" ||
      Values.password === "" ||
      Values.rpassword === ""
    ) {
      setsendMessage({
        message: "Please fill out all empty fields.",
        status: "error",
        loader: false,
      });

      setValues({
        firstname: "",
        lastname: "",
        email: "",
        country: "",
        password: "",
        rpassword: "",
      });
    } else if (!validateEmail(Values.email)) {
      setsendMessage({
        message: "Please enter a valid email address.",
        status: "error",
        loader: false,
      });

      setValues({
        firstname: "",
        lastname: "",
        email: "",
        country: "",
        password: "",
        rpassword: "",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstname: Values.firstname,
          lastname: Values.lastname,
          email: Values.email,
          country: Values.country,
          password: Values.password,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/users/publisher/register`,
        requestOptions
      ).then((res) => {
        res.json().then((result) => {
          if (result.success) {
            dispatch({ type: "user_details", data: result });
            window.location.href = "/";
            sessionStorage.setItem(true);
            setValues({
              firstname: "",
              lastname: "",
              email: "",
              country: "",
              password: "",
              rpassword: "",
            });
          } else {
            // sessionStorage.setItem(false);
            setsendMessage({
              message: result.message,
              status: "error",
              loader: false,
            });

            setValues({
              firstname: "",
              lastname: "",
              email: "",
              country: "",
              password: "",
              rpassword: "",
            });
          }
        });
      });
    }
  };
  const [passwordState, setpasswordState] = useState("password");
  const [rpasswordState, setrpasswordState] = useState("password");
  const handleVisiblePassword = () => {
    passwordState === "password"
      ? setpasswordState("text")
      : setpasswordState("password");
  };
  const handleVisiblerPassword = () => {
    rpasswordState === "password"
      ? setrpasswordState("text")
      : setrpasswordState("password");
  };
  return (
    <div className="row">
      {sendMessage.message ? (
        <div className="col-lg-12 alert__div__cover">
          <SimpleAlerts details={{ ...sendMessage }} />
        </div>
      ) : (
        ""
      )}
      <div className="form-group col-md-6 col-sm-12 form__div">
        <input
          type="text"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="First Name"
          onChange={(evt) => handleChange(evt.target.value, "firstname")}
          value={Values.firstname}
        />
      </div>
      <div className="form-group col-md-6 col-sm-12 form__div">
        <input
          type="text"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Last Name"
          onChange={(evt) => handleChange(evt.target.value, "lastname")}
          value={Values.lastname}
        />
      </div>
      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type="email"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Email Address"
          value={Values.email}
          onChange={(evt) => handleChange(evt.target.value, "email")}
        />
      </div>
      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type="text"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Country"
          onChange={(evt) => handleChange(evt.target.value, "country")}
          value={Values.country}
        />
      </div>
      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type={passwordState}
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Password"
          onChange={(evt) => handleChange(evt.target.value, "password")}
          value={Values.password}
        />
        <VisibilityIcon className="visible" onClick={handleVisiblePassword} />
      </div>
      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type={rpasswordState}
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Re-enter Password"
          onChange={(evt) => handleChange(evt.target.value, "rpassword")}
          value={Values.rpassword}
        />

        <VisibilityIcon className="visible" onClick={handleVisiblerPassword} />
      </div>
      <div className="have__an__account col-12">
        Already have an account ?{" "}
        <Link to="/signin">
          <span className="sign__in__option">Sign In</span>
        </Link>
      </div>
      <div className="button__div col-12">
        {sendMessage.loader ? (
          <Button className="sign__up__button">Please wait...</Button>
        ) : (
          <Button className="sign__up__button" onClick={handleSubmit}>
            Sign Up
          </Button>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
