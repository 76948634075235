import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function ImageAvatars({ avaterUrl }) {
  const classes = useStyles();

  const userReducer = useSelector((state) => state.userReducer);
  const getUserDetails = userReducer.credentials;
  return (
    <div className={classes.root}>
      {avaterUrl ? (
        <Avatar alt="Remy Sharp" src={avaterUrl} className={classes.small} />
      ) : (
        <div className="no__advater__div">
          {getUserDetails.message.email.substring(0, 2).toUpperCase()}
        </div>
      )}
    </div>
  );
}
