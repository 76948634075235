import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import AudioIn from "../../images/volume.png";
import AudioMute from "../../images/mute.png";
import firebase from "firebase";
import { firestore } from "../../config/db";

import Axios from "axios";
import MyBooksTable from "./mybooksTable";
import PaginationLink from "./pagination";
import { useParams } from "react-router";
import axios from "axios";
import { Button } from "@material-ui/core";
import SimpleAlerts from "../../components/alert/Alert";
import UploadImage from "../../images/upload.png";
import PdfFile from "../../images/pdf (1).png";
import emptyFile from "../../images/file-rounded-empty-sheet.png";
import Player from "./audioPlayer";
import { Link, useHistory } from "react-router-dom";
import ViewPdf from "./viewBook";
import DeleteAlert from "./deleteAlert";
import PageLoader from "../../components/pageLoader/loader";
import UploadAudioComponent from "./uploadAudioComponent";
import EpubPng from "../../images/epub.png";
import MenuIcon from "@material-ui/icons/Menu";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import "./viewBook.css"
export default function UpdateBookCover() {
  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials;
  const history = useHistory();
  const dispatch = useDispatch();
  const [authExpire, setauthExpire] = useState(false);
  const getAuthChecker = sessionStorage.getItem("userAuth");
  if (getAuthChecker === null || getAuthChecker === false) {
    window.location.href = "/signin";
  }
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;

  const userId = tokens.credentials.message._id;
  const [uploadImage, setuploadImage] = useState({
    published_image: "",
    loader: false,
  });
  const productId = useParams().productId;
  const [fieldDetails, setfieldDetails] = useState({
  
   
    image: "",
    audio: "",
    richAudio: "",
    bookFile: "",
   
    category: "",
    price: "",
    language: "",
   
  });
  const [category, setcategory] = useState("")
  const [title, settitle] = useState("");
  const [descriptionField, setdescriptionField] = useState("")
const [author, setauthor] = useState("")
const [isbn, setisbn] = useState("")
const [language, setlanguage] = useState("")
  const [getCategories, setgetCategories] = useState([]);
  const [price, setprice] = useState(0)
  const [messageDetails, setmessageDetails] = useState({
    message: "",
    status: "",
    loader: false,
  });
  const [updateLoader, setupdateLoader] = useState(false);
  useEffect(() => {
    setupdateLoader(true);
    axios.get(`${process.env.REACT_APP_URL}/category`).then((data) => {
      if (data.data.success) {
        setgetCategories(data.data.message);
      } else {
        setgetCategories([]);
      }
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/publish/book/publisher/${productId}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        setupdateLoader(false);
        if (data.success) {
          settitle(data.message.title)
          setauthor(data.message.author)
          setisbn(data.message.isbnNumber)
          setcategory(data.message.category._id?data.message.category._id:"")
          setlanguage( data.message.language);
          setprice(data.message.price);
          setfieldDetails({
           
            price: data.message.price,
            audio: data.message.audio,
            richAudio: data.message.richAudio,
            image: data.message.image,
            bookFile: data.message.bookFile,
           
          });
          setdescriptionField(data.message.description)
        } else {
          if (data.message === "jwt expired") {
            setauthExpire(true);
            sessionStorage.setItem(false);
          }
        }
      })
    );

    // in your case set state to returned token
  }, [messageDetails]);
  const handleUpdateDetails = (value, attr) => {
    setfieldDetails({
      ...fieldDetails,
      [attr]: value,
    });
    // setdescriptionField(...descriptionField)
  };
  const handleUpdateButton = () => {
    setmessageDetails({
      ...messageDetails,
      loader: true,
    });
    if(price===""||title===""||author===""||descriptionField===""||category===""||language===""||author===""){
      setmessageDetails({
        message: "Please enter required fields.",
        status: "error",
        loader: false,
      });
    }
    else if(category===""||category===null){
      setmessageDetails({
        message: "Please select category",
        status: "error",
        loader: false,
      });
    }
    else{
      const requestOptions = {
    
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${actualToken}`,
        },
        body: JSON.stringify({
          title: title,
          author: author,
          image: fieldDetails.image,
          audio: fieldDetails.audio,
          richAudio: fieldDetails.richAudio,
          bookFile: fieldDetails.bookFile,
          description: descriptionField,
          category: category,
          price:price,
          language: language,
          isbnNumber: isbn,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/publish/book/publisher/update/${productId}`,
        requestOptions
      )
        .then((data) => {
          data.json().then((result) => {
            if (result.success) {
              settitle(result.message.title)
              setauthor(result.message.author)
              setisbn(result.message.isbnNumber)
              setcategory(result.message.category.name? result.message.category.name:"");
              setlanguage(result.message.language);
              setprice(result.message.price)
              setfieldDetails({

                audio: result.message.audio,
                richAudio: result.message.richAudio,
                image: result.message.image,
                bookFile: result.message.bookFile,
               
              });
              setdescriptionField(result.message.description)
              setmessageDetails({
                message: "update successful",
                status: "success",
                loader: false,
              });
            } else {
              setmessageDetails({
                message: result.message,
                status: "error",
                loader: false,
              });
            }
          });
        })
        .catch((error) => {
          setmessageDetails({
            message: error.message,
            status: "error",
            loader: false,
          });
        });
    }
    
  };
  const [fileUpdateDetails, setfileUpdateDetails] = useState({
    imageUpload: "",
    audioUpload: "",
    richAudioUpload: "",
    bookFileUpload: "",
    loader1: false,
    loader2: false,
    loder3: false,
    loader4: false,
  });
  const handleImageUpload = (value, attr) => {
    setfileUpdateDetails({
      ...fileUpdateDetails,
      [attr]: value,
    });
  };

  const handleUploadImageButton = () => {
    setfileUpdateDetails({
      ...fileUpdateDetails,
      loader1: true,
    });
    const formData = new FormData();

    formData.append("file", fileUpdateDetails.imageUpload);
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_URL}/publish/image/${productId}`,
        formData,
        requestOptions
      )
      .then((data) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader1: false,
        });

        if (data.data.success) {
          console.log(data.data.message);
          setfileUpdateDetails({
            ...fileUpdateDetails,
            imageUpload: "",
          });
          settitle(data.data.message.title)
          setauthor(data.data.message.author)
          setisbn(data.data.message.isbnNumber)
          setcategory(data.data.message.category)
          setlanguage(data.data.message.language)
          setprice(data.data.message.price)
          setfieldDetails({
           
           
            image: data.data.message.image,
            audio: data.data.message.audio,
            richAudio: data.data.message.richAudio,
            bookFile: data.data.message.bookFile,
           
           
           
           
           
          });
          setdescriptionField(data.data.message.description)
          // console.log(data.data.message);
        } else {
          setmessageDetails({
            ...messageDetails,
            message: data.data.message,
            status: "error",
            loader1: false,
          });
          console.log(data.data.message);
        }
      })
      .catch((error) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader1: false,
        });
        setmessageDetails({
          ...messageDetails,
          message: error.message,
          status: "error",
          loader1: false,
        });
      });
  };
  const handleShortAudioUpload = (value, attr) => {
    const fileName = value.name;
    const fileExtension = fileName.split(".").pop();
    if (fileExtension === "mp3") {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        [attr]: value,
      });
    } else {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        audioUpload: "",
      });
      alert("the file you upload is not supported");
    }
  };
  const handleUploadShortAudioButton = () => {
    setfileUpdateDetails({
      ...fileUpdateDetails,
      loader2: true,
    });
    const formData = new FormData();

    formData.append("file", fileUpdateDetails.audioUpload);
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_URL}/publish/${productId}`,
        formData,
        requestOptions
      )
      .then((data) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader2: false,
        });

        if (data.data.success) {
          console.log(data.data.message);
          setfileUpdateDetails({
            ...fileUpdateDetails,
            audioUpload: "",
          });
          settitle(data.data.message.title)
          setauthor(data.data.message.author)
          setisbn(data.data.message.isbnNumber)
          setcategory(data.data.message.category)
          setlanguage(data.data.message.language)
          setprice(data.data.message.price)
          setfieldDetails({
           
           
            image: data.data.message.image,
            audio: data.data.message.audio,
            richAudio: data.data.message.richAudio,
            bookFile: data.data.message.bookFile,
           
           
           
           
           
          });
          setdescriptionField(data.data.message.description)
          // console.log(data.data.message);
        } else {
          setmessageDetails({
            ...messageDetails,
            message: data.data.message,
            status: "error",
            loader2: false,
          });
          console.log(data.data.message);
        }
      })
      .catch((error) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader2: false,
        });
        setmessageDetails({
          ...messageDetails,
          message: error.message,
          status: "error",
          loader2: false,
        });
      });
  };
  const handleRichAudioUpload = (value, attr) => {
    const fileName = value.name;
    const fileExtension = fileName.split(".").pop();
    if (fileExtension === "mp3") {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        [attr]: value,
      });
    } else {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        richAudioUpload: "",
      });
      alert("the file you upload is not supported");
    }
  };

  const handlePdfoUpload = (value, attr) => {
    const fileName = value.name;
    const fileExtension = fileName.split(".").pop();
    setfileUpdateDetails({
      ...fileUpdateDetails,
      [attr]: value,
    });
  
    if (fileExtension === "epub"||fileExtension === "pdf"||fileExtension === "docx") {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        [attr]: value,
      });
    } else {
      setfileUpdateDetails({
        ...fileUpdateDetails,
        bookFileUpload: "",
      });
      alert("the file you upload is not supported");
    }
  };
  const handleUploadPdfButton = () => {
    setfileUpdateDetails({
      ...fileUpdateDetails,
      loader4: true,
    });
    const formData = new FormData();

    formData.append("file", fileUpdateDetails.bookFileUpload);
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_URL}/publish/pdf/${productId}`,
        formData,
        requestOptions
      )
      .then((data) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader4: false,
        });

        if (data.data.success) {
          console.log(data.data.message);
          setfileUpdateDetails({
            ...fileUpdateDetails,
            bookFileUpload: "",
          });
          settitle(data.data.message.title)
          setauthor(data.data.message.author)
          setisbn( data.data.message.isbnNumber)
          setcategory(data.data.message.category)
          setlanguage(data.data.message.language)
          setprice(data.data.message.price)
          setfieldDetails({
            
           
            image: data.data.message.image,
            audio: data.data.message.audio,
            richAudio: data.data.message.richAudio,
            bookFile: data.data.message.bookFile,
           
            
         
          
           
          });
          setdescriptionField(data.data.message.description)
          // console.log(data.data.message);
        } else {
          setmessageDetails({
            ...messageDetails,
            message: data.data.message,
            status: "error",
            loader4: false,
          });
          console.log(data.data.message);
        }
      })
      .catch((error) => {
        setfileUpdateDetails({
          ...fileUpdateDetails,
          loader4: false,
        });
        setmessageDetails({
          ...messageDetails,
          message: error.message,
          status: "error",
          loader4: false,
        });
      });
  };
  const handleRouteToRead = () => {
 
    dispatch({
      type: "book_link",
      data: {
        bookFile: fieldDetails.bookFile,
        bookId: productId,
        title: title,
      },
    });
    history.push("/read");
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  const handlDescription=(value)=>{
    setdescriptionField(value)
    setfieldDetails({
      ...fieldDetails
    })
    
  }
  const handleUpdateTitle=(value)=>{
    settitle(value)
  }
  const handleUpdateAuthor=(value)=>{
    setauthor(value)
  }
  const handleUpdateISBN=(value)=>{
    setisbn(value)
  }
  const handlecategory=(value)=>{
setcategory(value)
  }
  const handleUpdateLanguage=(value)=>{
setlanguage(value);
  }
  const handleUpdatePrice=(value)=>{
    setprice(value)
  }
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : userData ? (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          {updateLoader ? (
            <PageLoader />
          ) : (
            <div>
              <div className="publisher__main__cover row m-0">
                <div className="col-12">
                  <label>Title</label>
                  <input
                    type="text"
                    className="published__input form-control"
                    placeholder="Title"
                  
                    value={title}
                    onChange={(evt) =>
                      handleUpdateTitle(evt.target.value)
                    }
                  />
                  <label>Author</label>
                  <input
                    type="text"
                    className="published__input"
                    placeholder="Enter Author name"
                    value={author}
                    onChange={(evt) =>
                      handleUpdateAuthor(evt.target.value,)
                    }
                  />
                  <label>Enter ISBN Number</label>
                  <input
                    type="text"
                    className="published__input"
                    placeholder="Enter ISBN number"
                    value={isbn}
                    onChange={(evt) =>
                      handleUpdateISBN(evt.target.value,)
                    }
                  />

                  <label>Category</label>
                  <select
                    class="custom-select published__input"
                    id="inputGroupSelect01"
                    value={category}
                    onChange={(evt) =>
                      handlecategory(evt.target.value)
                    }
                  >
                    {getCategories.length
                      ? getCategories.map((category) => {
                          return (
                            <option value={category._id}>
                              {category.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                  <label>Language</label>
                  <input
                    type="text"
                    className="published__input"
                    placeholder="Enter language"
                    value={language}
                    onChange={(evt) =>
                      handleUpdateLanguage(evt.target.value)
                    }
                  />
                  <label>($)Price (if book is free represent the value to be <span style={{color:"green",fontWeight:"700"}}>0</span>)</label>
                  <input
                    type="number"
                    className="published__input"
                    placeholder="Enter amount"
                    value={price}
                    onChange={(evt) =>
                      handleUpdatePrice(evt.target.value)
                    }
                  />
                  <label>Description</label>
                  <ReactQuill value={descriptionField}
                  onChange={
                    handlDescription} />
                  {/* <textarea
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    rows="5"
                    value={fieldDetails.description}
                    onChange={(evt) =>
                      handleUpdateDetails(evt.target.value, "description")
                    }
                  ></textarea> */}

                  {messageDetails.message ? (
                    <SimpleAlerts details={{ ...messageDetails }} />
                  ) : (
                    ""
                  )}
                  {messageDetails.loader ? (
                    <Button className="save__button">Please wait...</Button>
                  ) : (
                    <Button
                      className="save__button"
                      onClick={handleUpdateButton}
                    >
                      Update
                    </Button>
                  )}
                  <div style={{ width: "100%", paddingTop: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      Update book cover
                    </div>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ cursor: "pointer", position: "relative" }}>
                        <label
                          htmlFor="upload_book_cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={UploadImage}
                            alt="image_logo"
                            style={{
                              position: "absolute",
                              left: "40%",
                              top: "25%",
                            }}
                          />
                          {fileUpdateDetails.imageUpload ? (
                            <div
                              style={{ paddingTop: "40px", fontWeight: "700" }}
                            >
                              Image found click to upload
                            </div>
                          ) : (
                            <div>
                              {fieldDetails.image ? (
                                <img
                                  src={fieldDetails.image}
                                  alt="book cover"
                                  style={{ width: "200px", height: "200px" }}
                                />
                              ) : (
                                <div
                                  style={{
                                    paddingTop: "40px",
                                    fontWeight: "700",
                                  }}
                                >
                                  No image cover
                                </div>
                              )}
                            </div>
                          )}
                        </label>
                      </div>
                      <input
                        type="file"
                        id="upload_book_cover"
                        style={{ display: "none" }}
                        onChange={(evt) =>
                          handleImageUpload(evt.target.files[0], "imageUpload")
                        }
                      />
                    </div>
                  </div>
                  {fileUpdateDetails.loader1 ? (
                    <Button className="save__button">Please wait...</Button>
                  ) : (
                    <Button
                      className="save__button"
                      onClick={handleUploadImageButton}
                    >
                      Update book cover
                    </Button>
                  )}

                  {/*PDF FILE upload section */}
                  <div style={{ width: "100%", paddingTop: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      Upload PDF/EPUB Files.
                    </div>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ cursor: "pointer", position: "relative" }}>
                        <label
                          htmlFor="upload_pdf_cover"
                          style={{ cursor: "pointer" }}
                        >
                          {fileUpdateDetails.bookFileUpload ? (
                            <div
                              style={{ paddingTop: "40px", fontWeight: "700" }}
                            >
                              file found click to upload
                            </div>
                          ) : (
                            <div>
                              {fieldDetails.bookFile ? (
                                <div>
                                  <img src={EpubPng} alt="book cover" /> file
                                  available(Click on icon to change file)
                                </div>
                              ) : (
                                <div
                                  style={{
                                    paddingTop: "40px",
                                    fontWeight: "700",
                                  }}
                                >
                                  <img src={emptyFile} alt="book cover" />
                                  file is empty(Click on icon to upload)
                                </div>
                              )}
                            </div>
                          )}
                        </label>
                      </div>
                      <input
                        type="file"
                        id="upload_pdf_cover"
                        style={{ display: "none" }}
                        onChange={(evt) =>
                          handlePdfoUpload(
                            evt.target.files[0],
                            "bookFileUpload"
                          )
                        }
                      />
                    </div>
                  </div>
                  {fileUpdateDetails.loader4 ? (
                    <Button className="save__button">Please wait...</Button>
                  ) : (
                    <Button
                      className="save__button"
                      onClick={handleUploadPdfButton}
                    >
                      Upload File
                    </Button>
                  )}
                  <div
                    style={{ paddingTop: "50px", width: "100%" }}
                    className="row"
                  >
                    {fieldDetails.audio ? (
                      <div className="col-lg-6 col-md-12 col-12">
                        introductory audio
                        <Player url={fieldDetails.audio} />
                      </div>
                    ) : (
                      ""
                    )}
                    {fieldDetails.richAudio ? (
                      <div className="col-lg-6 col-md-12 col-12">
                        main content audio
                        <Player url={fieldDetails.richAudio} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* upload audio components */}
                  <div className="col-12">
                    <UploadAudioComponent productId={productId} />
                  </div>
                  {
                    fieldDetails.bookFile===""?"": <div style={{ marginTop: "50px" }}>
                    <Button
                      style={{
                        background: "#28C76F",
                        color: "white",
                        width: "50%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "26px",
                      }}
                      onClick={handleRouteToRead}
                    >
                      view Book
                    </Button>
                  </div>
                  }
                 
                  {/* <div style={{ paddingTop: "40px" }}>
                    <DeleteAlert
                      productId={productId}
                      actualToken={actualToken}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          )}
          {/* <div style={{ width: "100%" }}>
            <ViewPdf url={fieldDetails.bookFile} />
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
