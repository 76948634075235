import React from "react";
import "../registerFolder/RegistrationPage.css";
import Logo from "../../images/dot-logo.png";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import SimpleAlert from "../../components/alert/Alert";
export default function SignInPage() {
  const email = useParams().email;
  const history = useHistory();
  const [recoverCode, setrecoverCode] = useState(false);
  const [code, setcode] = useState("");
  const [messageDetails, setmessageDetails] = useState({
    message: "",
    status: "",
  });
  const [correctCode, setcorrectCode] = useState(false);
  const [resetPassword, setresetPassword] = useState({
    password: "",
    rpassword: "",
  });
  const [loader, setloader] = useState(false);
  useEffect(() => {
    if (email === "" || email === undefined) {
      history.push("/login");
    }
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/changePasswordCode/${email}`
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setrecoverCode(true);
        } else {
          // setmessageDetails({
          //   message: result.message,
          //   status: "error",
          // });
        }
      });
    });
  });
  const handleCodeChanges = (code) => {
    setcode(code);
  };
  const handleCheckCode = () => {
    setloader(true)
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
       code:code
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/checkConfirmationCode/${email}`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setcorrectCode(true);
      setmessageDetails({
        message: "",
        status: "",
      });
          setloader(false);
        } else {
          setcorrectCode(false);
          setloader(false);
          setmessageDetails({
            message: result.message,
            status: "error",
          });
          
        }
      });
    });

  };
  const handlePasswordResetChange = (value, attr) => {
    setresetPassword({
      ...resetPassword,
      [attr]: value,
    });
  };

  const handleResetPassword = () => {
    setloader(true);
    if (resetPassword.password === "") {
      setloader(false);
      setmessageDetails({
        message: "Please enter the required fields",
        status: "error",
      });
      setresetPassword({
        password: "",
        rpassword: "",
      });
    } else if (resetPassword.password !== resetPassword.rpassword) {
      setloader(false);
      setmessageDetails({
        message: "The password you enter does not match",
        status: "error",
      });
      setresetPassword({
        password: "",
        rpassword: "",
      });
    } else if (resetPassword.password.length < 6) {
      setloader(false);
      setmessageDetails({
        message: "Please enter atleast 6 characters.",
        status: "error",
      });
      setresetPassword({
        password: "",
        rpassword: "",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: resetPassword.password,
          email,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/users/publisher/resetPassword`,
        requestOptions
      ).then((res) => {
        res.json().then((result) => {
          if (result.success) {
            setmessageDetails({
              message: "Password Successfully change.",
              status: "success",
            });
            setresetPassword({
              password: "",
              rpassword: "",
            });
            setloader(false);
          } else {
            setloader(false);
            setmessageDetails({
              message: result.message,
              status: "error",
            });
            setresetPassword({
              password: "",
              rpassword: "",
            });
          }
        });
      });
    }
  };
  return (
    <div className="regCover">
      <div className="reg__form__cover">
        <div className="cont__logo">
          <img src={Logo} alt="logo" className="logo__image" />
          <div className="site__name"> Cawadi</div>
        </div>
        <div className="sub__heading">
          {correctCode ? "Enter password" : "Enter email notification code"}
        </div>
        <div className="container col-md-5 col-sm-12">
          <div className="form-group col-md-12 col-sm-12 form__div">
            {messageDetails.message ? (
              <div style={{ marginBottom: "20px" }}>
                <SimpleAlert details={messageDetails} />
              </div>
            ) : (
              ""
            )}
            {correctCode ? (
              <div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="password"
                    className="form-control reg__input__form"
                    id="formGroupExampleInput"
                    placeholder="Enter Password"
                    onChange={(evt) =>
                      handlePasswordResetChange(evt.target.value, "password")
                    }
                    value={resetPassword.password}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <input
                    type="password"
                    className="form-control reg__input__form"
                    id="formGroupExampleInput"
                    placeholder="Confirm Password"
                    onChange={(evt) =>
                      handlePasswordResetChange(evt.target.value, "rpassword")
                    }
                    value={resetPassword.rpassword}
                  />
                </div>
                {loader ? (
                  <Button
                    className="sign__up__button"
                    style={{ marginTop: "20px" }}
                  >
                    Please wait...
                  </Button>
                ) : (
                  <Button
                    className="sign__up__button"
                    style={{ marginTop: "20px" }}
                    onClick={handleResetPassword}
                  >
                    Reset Password
                  </Button>
                )}
                <div
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  click to login
                  <Link to="/signin" style={{ color: "#4A26D0" }}>
                    {" "}
                    login
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="number"
                  className="form-control reg__input__form"
                  id="formGroupExampleInput"
                  placeholder="Enter code"
                  onChange={(evt) => handleCodeChanges(evt.target.value)}
                  value={code}
                />
                {
                  loader? <Button
                  className="sign__up__button"
                  style={{ marginTop: "20px" }}
                 
                >
                  Please wait...
                </Button>: <Button
                  className="sign__up__button"
                  style={{ marginTop: "20px" }}
                  onClick={handleCheckCode}
                >
                  Send
                </Button>
                }
               
                <div
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  didn't get any mail?
                  <Link to="/forgot-password" style={{ color: "#4A26D0" }}>
                    {" "}
                    forgot password
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
