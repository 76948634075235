import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import SalesDetails from "./salesDetails";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function OrderTable({ orderDetails }) {
  console.log(orderDetails);
  const classes = useStyles();
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const userId = tokens.credentials.message._id;

  //   const getBooks = mybooks
  //     ? mybooks.map((lists) => {
  //         return console.log(lists);
  //       })
  //     : "";
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Bank Name</TableCell>

            <TableCell align="left">Amount Paid</TableCell>
            <TableCell align="left">Payment Type</TableCell>
            <TableCell align="left">Transaction Date</TableCell>
            <TableCell align="left">Buyer Name</TableCell>
            <TableCell align="left">Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {orderDetails ? orderDetails.bankName : ""}
            </TableCell>
            <TableCell align="left">
              {orderDetails ? "$" + orderDetails.amountPaid : ""}
            </TableCell>

            <TableCell align="left">
              {orderDetails ? orderDetails.paymentType : ""}
            </TableCell>

            <TableCell align="left">
              {orderDetails ? orderDetails.transactionDate : ""}
            </TableCell>
            <TableCell align="left">
              {orderDetails ? orderDetails.transactionName : ""}
            </TableCell>

            <TableCell align="left">
              {orderDetails
                ? moment(orderDetails.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                : ""}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
