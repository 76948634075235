import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import SimpleAlerts from "../../components/alert/Alert";
import AudioList from "./audioList";
import Audio from "../../images/podcast (1).png";
export default function UploadAudioComponent({ productId }) {
  const id = productId;

  const [uploadAudioFile, setuploadAudioFile] = useState("");
  const [messageDetails, setmessageDetails] = useState("");
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const [loader, setloader] = useState(false);
  const [productAudios, setproductAudios] = useState([]);
  const [reload, setreload] = useState("");
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${actualToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const handleShortAudioUpload = (value, attr) => {
    const fileName = value.name;

    const fileExtension = fileName.split(".").pop();
    if (fileExtension === "mp3") {
      setuploadAudioFile(value);
      setmessageDetails("");
    } else {
      setuploadAudioFile("");
      setmessageDetails({
        status: "error",
        message: "The file  entered is not supported.",
      });
      alert("the file you upload is not supported");
      return;
    }
  };

  const handleUploadRichAudioButton = () => {
    setloader(true);
    const formData = new FormData();
    formData.append("file", uploadAudioFile);

    Axios.post(
      `${process.env.REACT_APP_URL}/publish/rich/audio/${id}`,
      formData,
      requestOptions
    )
      .then((res) => {
        if (res.data.success) {
          setloader(false);
          setuploadAudioFile("");
          setmessageDetails({
            status: "success",
            message: "Audio uploaded successful.",
          });
        } else {
          setloader(false);
          setuploadAudioFile("");
          setmessageDetails({
            status: "error",
            message: "An error occure please try again",
          });
        }
      })
      .catch((error) => {
        setmessageDetails({
          status: "error",
          message: error.message,
        });
      });
  };
  const stateReloader = (data) => {
    setreload(data);
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/publish/audio/byPublisherId/${id}`,
      requestOptions
    ).then((res) => {
      res.json().then((data) => {
        setproductAudios(data.message);
      });
    });
  }, [messageDetails, reload]);

  return (
    <div className="row">
      <div
        className="col-12"
        style={{ marginBottom: "10px", fontWeight: "700" }}
      >
        {messageDetails === "" ? (
          "Uplaod An audio Files(The first audio upload should be an introductory file)"
        ) : (
          <SimpleAlerts details={messageDetails} />
        )}
      </div>
      <div className="col-lg-5">
        <div
          style={{
            padding: "10px",
            background: "#E5E5E5",
            borderRadius: "5px",
          }}
        >
          <input
            type="file"
            id="upload_pdf_cover"
            style={{ width: "100%" }}
            onChange={(evt) => handleShortAudioUpload(evt.target.files[0])}
          />

          {uploadAudioFile ? (
            loader ? (
              <Button className="save__button">Please wait...</Button>
            ) : (
              <Button
                className="save__button"
                onClick={handleUploadRichAudioButton}
              >
                Upload Audio
              </Button>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col" style={{ height: "300px", overflowY: "scroll",marginTop:"20px" }}>
        <div
          style={{
            fontWeight: "700",
            marginBottom: "10px",
            width: "100%",
            textAlign: "center",
            color: "#6019CF",
          }}
        >
          Audio List
        </div>
        {productAudios.length?productAudios.map((list,index) => (
          <AudioList
            key={list._id}
            details={list}
            index={index}
            stateReloaderC={stateReloader}
          />
        )):<div style={{fontWeight:"bold",paddingTop:"40px"}}>
         <div style={{width:"100%",display:"flex",justifyContent:"center"}}> <img src={Audio} alt="logo"/></div>
          <div style={{width:"100%",justifyContent:"center",display:"flex"}}>No audio content found</div></div>}
      </div>
    </div>
  );
}
