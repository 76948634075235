import React from "react";
import "./Header.css";
import SearchIcon from "@material-ui/icons/Search";
import Notification from "./Notification";
import HeaderAvatars from "./headerAvater";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function Header() {
  const history = useHistory();
  const userReducer = useSelector((state) => state.userReducer);
  const publisherAuth = userReducer.credentials;
  const [searchBooks, setsearchBooks] = useState({
    books: [],
  });
  if (publisherAuth === null) {
    window.location.href = "/signin";
  }
  const [setSearchValue, setsetSearchValue] = useState({
    search: "",
  });
  const handleSearch = (value) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${publisherAuth.token}`,
      },
      body: JSON.stringify({
        search: value ? value : "",
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/publish/books/search/${publisherAuth.message._id}`,
      requestOptions
    )
      .then((res) => {
        res.json().then((data) => {
          setsearchBooks({
            books: data.message,
          });
        });
      })
      .catch((error) => {
        setsearchBooks({
          books: [],
        });
      });
  };

  return (
    <div className="header">
      <SearchIcon className="search__icon" />
      <input
        type="text"
        className="form-control search__input"
        placeholder="Search books"
        onChange={(evt) => handleSearch(evt.target.value, "search")}
      />
      <div className="header__avater">
        <HeaderAvatars />
      </div>
      <div className="notification">{/* <Notification /> */}</div>
      {searchBooks.books.length ? (
        <div
          style={{
            position: "absolute",
            paddingTop: "10px",
            paddingBottom: "20px",
            paddingLeft:"10px",
            paddingRight:"10px",
           
            background: 'rgba(96,25,207,.7)',
            width: "100%",
            marginTop: "10px",
            zIndex: 1,
            height:"60vh",
            overflowY:"scroll"
          }}
        >
          <ul style={{ listStyle: "none", width: "100%" }}>
            {searchBooks.books.length
              ? searchBooks.books.map((list) => (
                  <li
                    key={list.key}
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      width: "100%",
                      cursor: "pointer",
                      marginBottom: "10px",
                      color: "#1941CF",
                    }}
                    onClick={() => history.push("/update/book/" + list._id)}
                  >
                    {list.title}
                  </li>
                ))
              : "Not found"}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
