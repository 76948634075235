import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "firebase";
import { firestore } from "../../config/db";
import BookLogo from "../../images/book-outline.png";
import Axios from "axios";
import MyBooksTable from "./mybooksTable";
import PaginationLink from "./pagination";
import PageLoader from "../../components/pageLoader/loader";
import SearchList from "../../components/search";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
export default function MyBookCover() {
  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials;

  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const [authExpire, setauthExpire] = useState(false);
  const tokens = JSON.parse(getTokens.userReducer);
  if (getTokens === null || tokens.initState || tokens.credentials === null) {
    window.location.href = "/signin";
  }
  const actualToken = tokens.credentials.token;

  const userId = tokens.credentials.message._id;
  const [uploadImage, setuploadImage] = useState({
    published_image: "",
    loader: false,
  });
  const [mybooks, setmybooks] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [bookLoader, setbookLoader] = useState(false);
  const [search, setsearch] = useState("");
  const [pageNumberChange, setpageNumberChange] = useState(1)
  useEffect(() => {
    setbookLoader(true);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_URL
      }/publish/publisher/books/${userId}?page=${1}&size=${10}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        setbookLoader(false);
        if (data.success) {
          setmybooks(data.message);
        } else {
          if (data.message === "jwt expired") {
            setauthExpire(true);
            sessionStorage.setItem(false);
          } else {
            setmybooks([]);
          }
        }
      })
    );
    fetch(
      `${process.env.REACT_APP_URL}/publish/get/count/publisher/${userId}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          const newCount = Math.ceil(data.message / 10);
          settotalCount(newCount);
        } else {
          settotalCount(0);
        }
      })
    );

    // in your case set state to returned token
  }, []);
  const handleChange = (pageNumber) => {
    setbookLoader(true);
    setpageNumberChange(pageNumber)
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_URL
      }/publish/publisher/books/${userId}?page=${pageNumber}&size=${10}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        setbookLoader(false);
        if (data.success) {
          setmybooks(data.message);
          
        } else {
          setmybooks(data.message);
          
        }
      })
    );
  };
 
  const [SearchBook, setSearchBook] = useState([]);
  const handleSearch = (value) => {
    setsearch(value);
    if (value.length > 3) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${actualToken}`,
        },
        body: JSON.stringify({
          search: value.length ? value : "",
        }),
      };
      fetch(
        `${
          process.env.REACT_APP_URL
        }/publish/publisher/books/search/${userId}?page=${1}&size=${10}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            let newCount = Math.ceil(data.message.length / 10);
            // settotalCount(newCount);
            // setmybooks(data.message);
            setSearchBook(data.message);
          }
        })
      );
    } else {
      setSearchBook([]);
    }
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : userData ? (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#6019CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          {bookLoader ? (
            <PageLoader />
          ) : (
            <div className="publisher__main__cover row m-0">
              <div
                className="header__books col-12"
                style={{ marginBottom: "20px", color:"#6019CF",fontWeight:"700" }}
              >
                <div className="row">
                  <div className="col-md-6"> Books Published</div>
                  <div className="col-md-6">
                    <div class="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <SearchIcon style={{color:"#6019CF"}}/>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search title..."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{
                          fontSize: "17px",
                        }}
                        value={search}
                        onChange={(evt) => handleSearch(evt.target.value)}
                      />
                    </div>
                    {SearchBook.length ? (
                      <SearchList searchDetails={SearchBook} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {mybooks ? (
                mybooks.length ? (
                  <div className="col-12">
                    {" "}
                    <div style={{ height: "500px", overflowY: "scroll" }}>
                      <MyBooksTable mybooks={mybooks} />
                    </div>
                    <div>
                      <PaginationLink
                        handleChange1={handleChange}
                        count={totalCount}
                        pageNumberChange={pageNumberChange}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "20%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <img src={BookLogo} alt="book__logo" />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      No book published
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
