import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

export default function SearchList({ searchDetails }) {
  const history = useHistory();
  const classes = useStyles();
  console.log({ searchDetails });
  return (
    <List className={classes.root} subheader={<li />}>
      <li className={classes.listSection}>
        <ul className={classes.ul}>
          {/* <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader> */}
          {searchDetails.map((item, index) => (
            <ListItem key={index}>
              <div
                class="card"
                onClick={() => history.push("/update/book/" + item._id)}
                style={{ cursor: "pointer" }}
              >
                <div class="card-body" style={{ fontSize: "15px" }}>
                  {item.title}
                </div>
              </div>
            </ListItem>
          ))}
        </ul>
      </li>
    </List>
  );
}
