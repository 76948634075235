import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import firebase from "firebase";
import "./publishPage.css";
import UploadImage from "../../images/upload.png";
import PdfIcon from "../../images/pdf.png";
import Spinner from "../../components/spinner/Spinner";
import SimpleAlerts from "../../components/alert/Alert";
import { Button } from "@material-ui/core";
import { firestore } from "../../config/db";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ISBNPayment from "./ISBNPayment";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
export default function PublisherCover() {
  const [messageDetails, setmessageDetails] = useState({
    loader: false,
    message: "",
    status: "",
    serverMessage: false,
    bookId: "",
  });
  const userReducer = useSelector((state) => state.userReducer);
  const publisherAuth = userReducer.credentials;

  const userData = publisherAuth ? publisherAuth.message : "";
  const history = useHistory();
  const [getCategories, setgetCategories] = useState([]);
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  if (publisherAuth === null || tokens.initState) {
    window.location.href = "/signin";
  }
  const actualToken = tokens.credentials.token;

  const userId = tokens.credentials.message._id;
  const [publishedDetails, setpublishedDetails] = useState({
    bookFile: "",
    description: "",
    price: "",
    language: "",
    category: "",
    author: "",
    title: "",
    image: "",
    userId: userId,
    isFeatured: false,
    newArrival: false,
    bestSeller: false,
    audio: "",
    richAudio: "",
    isbnNumber: "",
  });
  const [fileUploads, setfileUploads] = useState({});
  const [authExpire, setauthExpire] = useState(false);
  const handleBookDetails = (value, attr) => {
    setpublishedDetails({
      ...publishedDetails,
      [attr]: value,
    });
  };
  const handleUploadImageCoverDetails = (value, attr) => {
    const fileName = value.name;
    const fileExtension = fileName.split(".").pop();
    console.log(fileName);
    if (
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "jpeg"
    ) {
      setpublishedDetails({
        ...publishedDetails,
        [attr]: value,
      });
    } else {
      alert("File not supported");
      setpublishedDetails({
        ...publishedDetails,
        [attr]: "",
      });
    }
  };
  const handlePdfUploadDetails = (value, attr) => {
    const fileName = value.name;
    const fileExtension = fileName.split(".").pop();
   
    if (fileExtension !== "epub") {
      alert("File not supported");
      setpublishedDetails({
        ...publishedDetails,
        [attr]: "",
      });
    } else {
      setpublishedDetails({
        ...publishedDetails,
        [attr]: value,
      });
    }
  };
  const [publisherDetails, setpublisherDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyName: "",
    location: "",
    country: "",
    phoneNumber: "",
    canPublishBook: "",
  });
  const [reloadPublishPayment, setreloadPublishPayment] = useState(false);
  const reloadPublisher = (data) => {
    setreloadPublishPayment(data);
  };
  useEffect(() => {
    const requestOptions1 = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/profile/${userId}`,
      requestOptions1
    ).then((res) => {
      res.json().then((data) => {
       
        if (data.success) {
          setpublisherDetails({
            firstname: data.message.firstName,
            lastname: data.message.lastName,
            email: data.message.email,
            companyName: data.message.companyName,
            location: data.message.location,
            country: data.message.country,
            phoneNumber: data.message.phoneNumber,
            canPublishBook: data.message.canPublishBook,
            // canPublishBook:true
          });
        } else {
          if (data.message === "jwt expired") {
            setauthExpire(true);
            sessionStorage.setItem(false);
          }
        }
      });
    });
  }, [reloadPublishPayment]);
  const UpdatePublisher = () => {
    console.log({ publisherUpdated: true });
    const requestOptionsU = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actualToken}`,
      },
      body: JSON.stringify({
        firstName: publisherDetails.firstname,
        lastName: publisherDetails.lastname,
        email: publisherDetails.email,
        phoneNumber: publisherDetails.phoneNumber,
        companyName: publisherDetails.companyName,
        location: publisherDetails.location,
        country: publisherDetails.country,
        canPublishBook: false,
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/users/publisher/profile/${userId}`,
      requestOptionsU
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          history.push("/books");
          setpublisherDetails({
            firstname: data.message.firstName,
            lastname: data.message.lastName,
            email: data.message.email,
            phoneNumber: data.message.phoneNumber,
            companyName: data.message.companyName,
            location: data.message.location,
            country: data.message.country,
            canPublishBook: data.message.canPublishBook,
          });
        } else {
        }
      })
    );
  };
  const handleUplaod = () => {
    setmessageDetails({
      ...messageDetails,
      loader: true,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actualToken}`,
      },
      body: JSON.stringify({
        bookFile: publishedDetails.bookFile,
        description: publishedDetails.description,
        price: publishedDetails.price,
        language: publishedDetails.language,
        category: publishedDetails.category,
        author: publishedDetails.author,
        title: publishedDetails.title,
        image: publishedDetails.image,
        userId: publishedDetails.userId,
        isFeatured: false,
        newArrival: false,
        bestSeller: false,
        audio: publishedDetails.audio,
        richAudio: publishedDetails.richAudio,
        isbnNumber: publishedDetails.isbnNumber,
      }),
    };

    fetch(`${process.env.REACT_APP_URL}/publish/`, requestOptions)
      .then((data) => {
        data.json().then((data) => {
          if (data.success) {
            UpdatePublisher();
            setmessageDetails({
              ...messageDetails,
              loader: false,
              serverMessage: data.success,
              message: "book details upload successful",
              status: "success",
              bookId: data.message._id,
            });
          } else {
            setmessageDetails({
              ...messageDetails,
              loader: false,
              serverMessage: data.success,
              message: data.message,
              status: "error",
            });
          }
        });
      })
      .then((error) => {
        setmessageDetails({
          ...messageDetails,
          loader: false,
          serverMessage: false,
          message: "there was an error",
          status: "error",
        });
      });
  };
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/category`).then((data) => {
      if (data.data.success) {
        setgetCategories(data.data.message);
      } else {
        setgetCategories([]);
      }
    });
  }, []);

  const [bookCover, setbookCover] = useState("");
  const [imageCoverLoader, setimageCoverLoader] = useState(false);
  const handleUploadCover = () => {
    if (publishedDetails.image === "") {
      setmessageDetails({
        ...messageDetails,
        message: "please upload an image file",
        status: "error",
      });
      setpublishedDetails({
        ...publishedDetails,
        image: "",
      });
    } else {
      setimageCoverLoader(true);
      const formData = new FormData();

      formData.append("file", publishedDetails.image);
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_URL}/publish/image/${messageDetails.bookId}`,
          formData,
          requestOptions
        )
        .then((data) => {
          setimageCoverLoader(false);
          if (data.data.success) {
            setbookCover(data.data.message.image);
            setpublishedDetails({
              ...publishedDetails,
              image: "",
            });
          } else {
            setmessageDetails({
              ...messageDetails,
              message: data.data.message,
              status: "error",
            });
          }
        })
        .catch((error) => {
          setimageCoverLoader(false);
          setmessageDetails({
            ...messageDetails,
            message: error.message,
            status: "error",
          });
        });
    }
  };
  const [pdfLoaderButton, setpdfLoaderButton] = useState(false);
  const handleUploadPdf = () => {
    setpdfLoaderButton(true);
    const formData = new FormData();

    formData.append("file", publishedDetails.bookFile);
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_URL}/publish/pdf/${messageDetails.bookId}`,
        formData,
        requestOptions
      )
      .then((data) => {
        setpdfLoaderButton(false);
        if (data.data.success) {
          setbookCover(data.data.message.image);
          setpublishedDetails({
            ...publishedDetails,
            bookFile: "done",
          });
        } else {
          setmessageDetails({
            ...messageDetails,
            message: data.data.message,
            status: "error",
          });
        }
      })
      .catch((error) => {
        setpdfLoaderButton(false);
        setmessageDetails({
          ...messageDetails,
          message: error.message,
          status: "error",
        });
      });
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  const  handlDescription=(value)=>[
   setpublishedDetails(
    {
      ...publishedDetails,
      description:value
    }
   )
  ]
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="row m-0 dashboard__cover">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover" style={{ paddingBottom: "50px" }}>
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        {publisherDetails.canPublishBook ? (
          <div className="dashboard__cover">
            <div className="publisher__main__cover row m-0">
              <div class="alert alert-warning col-12" role="alert">
                You can proceed to publish your book. An ISBN Number will be
                sent to your Email please update your ISBN number in required
                field.
              </div>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "#1941CF",
                }}
              >
                Publish
              </div>
              {messageDetails.message ? (
                <div className="alert__cover col-12">
                  <SimpleAlerts details={{ ...messageDetails }} />
                </div>
              ) : (
                ""
              )}
              <div className="col input__cover__div">
                {messageDetails.serverMessage ? (
                  ""
                ) : (
                  <div>
                    <input
                      type="text"
                      className="published__input"
                      placeholder="Title"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "title")
                      }
                      value={publishedDetails.title}
                    />
                    <input
                      type="text"
                      className="published__input"
                      placeholder="Author"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "author")
                      }
                      value={publishedDetails.author}
                    />
                    <input
                      type="text"
                      className="published__input"
                      placeholder="Enter ISBN Number"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "isbnNumber")
                      }
                      value={publishedDetails.isbnNumber}
                    />
                    <select
                      class="custom-select published__input"
                      id="inputGroupSelect01"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "category")
                      }
                      value={publishedDetails.category}
                    >
                      <option selected value="">
                        Select Category
                      </option>
                      {getCategories.length
                        ? getCategories.map((category) => {
                            return (
                              <option value={category._id}>
                                {category.name}
                              </option>
                            );
                          })
                        : ""}
                    </select>

                    <input
                      type="text"
                      className="published__input"
                      placeholder="Language"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "language")
                      }
                      value={publishedDetails.language}
                    />
                    <labal>($)Price (if book is free represent the value to be <span style={{color:"green",fontWeight:"700"}}>0</span>)</labal>
                    <input
                      type="number"
                      className="published__input"
                      placeholder="Price"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "price")
                      }
                      value={publishedDetails.price}
                    />
                    {/* <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="Description"
                      onChange={(evt) =>
                        handleBookDetails(evt.target.value, "description")
                      }
                      value={publishedDetails.description}
                    /> */}
                     <ReactQuill value={publishedDetails.description}
                  onChange={
                    handlDescription} />
                    {/* <div className="pdf__upload__div__cover">
                <label htmlFor="publish__pdf" className="label__image__icon">
                  {" "}
                  <img src={PdfIcon} alt="logo" className="image__pdf__icon" />
                </label>
                <input
                  type="file"
                  id="publish__pdf"
                  style={{ display: "none" }}
                  onChange={(evt) =>
                    handleBookDetails(evt.target.files[0], "pdfFile")
                  }
                />
              </div> */}

                    {messageDetails.loader ? (
                      <Button className="save__button">Please wait...</Button>
                    ) : (
                      <Button className="save__button" onClick={handleUplaod}>
                        Save
                      </Button>
                    )}
                  </div>
                )}
                {messageDetails.serverMessage ? (
                  <div>
                    <div style={{ marginTop: "20px" }}>
                      Please upload Book cover(Image file)
                    </div>
                    <div
                      className="col-12 file__image__div"
                      style={{ marginTop: "20px" }}
                    >
                      {messageDetails.serverMessage ? (
                        <div>
                          <label
                            htmlFor="publish__file"
                            className="label__image__icon label__with__image"
                          >
                            {publishedDetails.image ? (
                              "Image available"
                            ) : bookCover ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={bookCover}
                                  alt="logo"
                                  style={{
                                    width: "30%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                                src={UploadImage}
                                alt="logo"
                                style={{
                                  position: "absolute",
                                  left: "35%",
                                  top: "45%",
                                }}
                              />
                            )}
                          </label>
                          <input
                            type="file"
                            id="publish__file"
                            style={{ display: "none" }}
                            onChange={(evt) =>
                              handleUploadImageCoverDetails(
                                evt.target.files[0],
                                "image"
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {imageCoverLoader ? (
                      <Button className="save__button">Please Wait...</Button>
                    ) : (
                      <Button
                        className="save__button"
                        onClick={handleUploadCover}
                      >
                        Upload Cover
                      </Button>
                    )}

                    <div className="pdf__upload__div__cover">
                      <div
                        style={{
                          position: "absolute",
                          top: "10%",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        {publishedDetails.bookFile === ""
                          ? "No file yet"
                          : publishedDetails.bookFile === "done"
                          ? "file successfully uploaded"
                          : "click on button to upload"}
                      </div>
                      <label
                        htmlFor="publish__pdf"
                        className="label__image__icon"
                      >
                        {" "}
                        <img
                          src={PdfIcon}
                          alt="logo"
                          className="image__pdf__icon"
                        />
                      </label>
                      <input
                        type="file"
                        id="publish__pdf"
                        style={{ display: "none" }}
                        onChange={(evt) =>
                          handlePdfUploadDetails(
                            evt.target.files[0],
                            "bookFile"
                          )
                        }
                      />
                    </div>
                    {pdfLoaderButton ? (
                      <Button className="save__button">Please wait...</Button>
                    ) : (
                      <Button
                        className="save__button"
                        onClick={handleUploadPdf}
                      >
                        Upload /Update file
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <ISBNPayment
            publisherName={
              publisherDetails.firstname + " " + publisherDetails.lastname
            }
            reloadPublisher={reloadPublisher}
            userid={userId}
          />
        )}
      </div>
    </div>
  );
}
