import { Button } from "@material-ui/core";
import React, { useState } from "react";
import BookCards from "./BookCards";
import "./BookCards.css";
export default function BookCardCover({ details }) {
  return (
    <div>
      <BookCards details={{ ...details }} />
    </div>
  );
}
