import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function HeaderAvatars() {
  const classes = useStyles();
  const userReducer = useSelector((state) => state.userReducer);
  const getUserDetails = userReducer.credentials;
  // const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  // const tokens = JSON.parse(getTokens.userReducer);

  const [avaterUrl, setavaterUrl] = useState("");

  useEffect(() => {
    if (userReducer === null) {
      window.location.href = "/signin";
    } else {
      const getTokens = JSON.parse(
        localStorage.getItem("persist:reducerStore")
      );
      const tokens = JSON.parse(getTokens.userReducer);
      if (getUserDetails === null || tokens.credentials === null) {
        window.location.href = "/signin";
      } else {
        const actualToken = tokens.credentials.token;
        const userId = tokens.credentials.message._id;
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${actualToken}`,
          },
        };
        fetch(
          `${process.env.REACT_APP_URL}/users/publisher/profile/${userId}`,
          requestOptions
        ).then((res) => {
          res.json().then((data) => {
            if (data.success) {
              setavaterUrl(data.message.avaterUrl);
            } else {
              setavaterUrl("");
            }
          });
        });
      }
    }
  }, []);

  return getUserDetails ? (
    <div className={classes.root}>
      {avaterUrl ? (
        <Avatar alt="Remy Sharp" src={avaterUrl} className={classes.small} />
      ) : (
        <div className={classes.small}>
          {getUserDetails.message.email.substring(0, 2).toUpperCase()}
        </div>
      )}
    </div>
  ) : (
    ""
  );
}
