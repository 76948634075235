import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";

import firebase from "firebase";
import { firestore } from "../../config/db";
import BookLogo from "../../images/edit.png";
import Axios from "axios";
import MenuIcon from "@material-ui/icons/Menu";
import PaginationLink from "../myBooks/pagination";
import PageLoader from "../../components/pageLoader/loader";
import SalesTable from "./salesTable";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
export default function SalesCover() {
  const userReducer = useSelector((state) => state.userReducer);
  const userData = userReducer.credentials;

  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));

  const tokens = JSON.parse(getTokens.userReducer);
  if (getTokens === null || tokens.initState || tokens.credentials === null) {
    window.location.href = "/signin";
  }
  const actualToken = tokens.credentials.token;

  const userId = tokens.credentials.message._id;
  const [uploadImage, setuploadImage] = useState({
    published_image: "",
    loader: false,
  });
  const [mybooks, setmybooks] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [bookLoader, setbookLoader] = useState(false);
  const getAuthChecker = sessionStorage.getItem("userAuth");
  const [authExpire, setauthExpire] = useState(false);
  useEffect(() => {
    setbookLoader(true);
    if (getAuthChecker === null || getAuthChecker === false) {
      window.location.href = "/signin";
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${actualToken}`,
        },
      };
      fetch(
        `${
          process.env.REACT_APP_URL
        }/order/user/order/publisher/${userId}?page=${1}&size=${10}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          setbookLoader(false);

          if (data.success) {
            setmybooks(data.message);
          } else {
            if (data.message === "jwt expired") {
              setauthExpire(true);
              sessionStorage.setItem(false);
            } else {
              setmybooks(data.message);
            }
          }
        })
      );
      fetch(
        `${process.env.REACT_APP_URL}/order/get/count/order/publisher/${userId}`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          if (data.success) {
            const newCount = Math.ceil(data.message / 10);
            settotalCount(newCount);
          } else {
            settotalCount(0);
          }
        })
      );
    }
    // in your case set state to returned token
  }, []);
  const [pageNumber, setpageNumber] = useState(1)
  const handleChange = (pageNumber) => {
    setbookLoader(true);
    setpageNumber(pageNumber)
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${actualToken}`,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_URL
      }/order/user/order/publisher/${userId}?page=${pageNumber}&size=${10}`,
      requestOptions
    ).then((response) =>
      response.json().then((data) => {
        setbookLoader(false);

        if (data.success) {
          setmybooks(data.message);
        } else {
          setmybooks(data.message);
        }
      })
    );
  };
  const [showSidebar, setshowSidebar] = useState(false);
  const handleClick = () => {
    if (showSidebar) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  };
  return authExpire ? (
    <div class="card">
      <div class="card-body">
        Your session has expired.
        <Link to="/signin">
          <Button
            className="btn btn-success"
            style={{ marginLeft: "5px", color: "white" }}
          >
            Click to Sign in
          </Button>
        </Link>
      </div>
    </div>
  ) : userData ? (
    <div className="row dashboard__cover m-0">
      <div className="col-md-2 side__bar__cover">
        <SideBar handAndShow={showSidebar} />
      </div>
      <div className="col routerPage__cover">
        <Header />
        <div className="icon__div">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginBottom: "10px",
            }}
          >
            <MenuIcon
              style={{ fontSize: "35px", color: "#1941CF", cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="dashboard__cover">
          {bookLoader ? (
            <PageLoader />
          ) : (
            <div className="publisher__main__cover row m-0">
              {mybooks.length ? (
                <div className="col-12">
                  {" "}
                  <div
                    className="header__books"
                    style={{ marginBottom: "20px", color: "#6019CF",fontWeight:"700" }}
                  >
                    Sales
                  </div>
                  <div style={{ height: "500px", overflowY: "scroll" }}>
                    <SalesTable mybooks={mybooks} />
                  </div>
                  <PaginationLink
                    handleChange1={handleChange}
                    count={totalCount}
                    pageNumberChange={pageNumber}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    paddingTop: "20%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img src={BookLogo} alt="book__logo" />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No sales found.
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
