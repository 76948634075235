import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function MyBooksTable({ mybooks }) {
  const classes = useStyles();
  const getTokens = JSON.parse(localStorage.getItem("persist:reducerStore"));
  const tokens = JSON.parse(getTokens.userReducer);
  const actualToken = tokens.credentials.token;
  const userId = tokens.credentials.message._id;

  //   const getBooks = mybooks
  //     ? mybooks.map((lists) => {
  //         return console.log(lists);
  //       })
  //     : "";
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">ISBN</TableCell>
            <TableCell align="left">Language</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mybooks.length
            ? mybooks.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.title.length>30?row.title.substring(0,30)+"...":row.title}
                  </TableCell>
                  <TableCell align="left">{row.category.name}</TableCell>
                  <TableCell align="left">{row.isbnNumber?row.isbnNumber:<span style={{color:"red",fontWeight:"700"}}>ISBN number not updated</span>}</TableCell>
                  <TableCell align="left">{row.language}</TableCell>
                  <TableCell align="left">${row.price}</TableCell>
                  <TableCell align="left">
                    <Link to={"/update/book/" + row._id}>
                      <Button style={{ background: "#6019CF", color: "white" }}>
                        Update
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
