import { Button } from "@material-ui/core";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { auth } from "../../config/db";
import SimpleAlerts from "../../components/alert/Alert";
import { useDispatch } from "react-redux";

const SignInForm = () => {
  const [Values, setValues] = useState({
    email: "",

    password: "",
  });
  const handleChange = (value, attr) => {
    setValues({
      ...Values,
      [attr]: value,
    });
  };
  const dispatch = useDispatch();
  const [sendMessage, setsendMessage] = useState({
    message: "",
    status: "",
    loader: false,
  });
  const handleSubmit = () => {
    setsendMessage({
      loader: true,
    });

    if (Values.email === "" || Values.password === "") {
      setsendMessage({
        message: "Please fill out all empty fields",
        status: "error",
        loader: false,
      });
      setValues({
        email: "",

        password: "",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: Values.email,
          password: Values.password,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/users/publisher/login`,
        requestOptions
      ).then((response) =>
        response.json().then((data) => {
          console.log(data);
          if (data.success) {
            dispatch({ type: "user_details", data });
            window.location.href = "/";
            sessionStorage.setItem("userAuth", true);
          } else {
            sessionStorage.setItem("userAuth", false);
            setsendMessage({
              message: data.message,
              status: "error",
              loader: false,
            });
            setValues({
              email: "",

              password: "",
            });
          }
        })
      );

      // in your case set state to returned token
    }
  };

  const [passwordState, setpasswordState] = useState("password");

  const handleVisiblePassword = () => {
    passwordState === "password"
      ? setpasswordState("text")
      : setpasswordState("password");
  };

  return (
    <div className="row">
      {sendMessage.message ? (
        <div className="col-lg-12 alert__div__cover">
          <SimpleAlerts details={{ ...sendMessage }} />
        </div>
      ) : (
        ""
      )}
      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type="email"
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Email Address"
          onChange={(evt) => handleChange(evt.target.value, "email")}
          value={Values.email}
        />
      </div>

      <div className="form-group col-md-12 col-sm-12 form__div">
        <input
          type={passwordState}
          className="form-control reg__input__form"
          id="formGroupExampleInput"
          placeholder="Password"
          onChange={(evt) => handleChange(evt.target.value, "password")}
          value={Values.password}
        />
        <VisibilityIcon className="visible" onClick={handleVisiblePassword} />
      </div>

      <div className="col-12">
        <div className="row">
          <div className=" col-6 ">
            {" "}
            <Link className="forgot__password" to="/forgot-password">
              Forgot Password{" "}
            </Link>
          </div>

          <div className=" col-md-6 col-12 have__an__account">
            Don't have an account ?{" "}
            <Link to="/register">
              <span className="sign__in__option">Sign Up</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="button__div col-12">
        {sendMessage.loader ? (
          <Button className="sign__up__button">Please wait...</Button>
        ) : (
          <Button className="sign__up__button" onClick={handleSubmit}>
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignInForm;
